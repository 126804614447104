import React from 'react';
import { OmApplicationPopupModal, OmIcon } from '@om/component-library-react';
import OmButton from '../../../../components/forms/OmButton';

const RemoveConfirmationModal = ({ open, dismissHandler, confirmHandler }) => {
    return (
        <OmApplicationPopupModal
            onModalClosed={() => dismissHandler()}
            name="LivesCoveredRemoveConfirmationModal"
            dismissible
            exitable
            open={open}
        >
            <div slot="content">
                <OmIcon imageUrl="https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/blt94f2cb596218bd62/5f2935c8ee8504335e705005/WRONG_CMOS.svg" />
                <p>Are you sure you want to remove this member?</p>
            </div>
            <OmButton
                slot="button"
                type="primary"
                size="small"
                text="Yes, remove"
                onClick={() => confirmHandler()}
            />
        </OmApplicationPopupModal>
    );
};

export default RemoveConfirmationModal;
