import React from 'react';
import OmButton from '../../../../components/forms/OmButton';
import { OmApplicationPopupModal } from '@om/component-library-react';

const IncompleteModal = ({ open, confirmHandler }) => {
    return (
        <OmApplicationPopupModal
            onModalClosed={() => confirmHandler()}
            name="LivesCoveredIncompleteModal"
            dismissible="true"
            exitable="true"
            open={open}>
            <div slot="content">
                <p>To proceed, fill in all the details for the members you’ve selected.</p>
            </div>
            <div slot="button">
                <OmButton
                    type="primary"
                    size="small"
                    text="Okay, thanks"
                    onClick={() => confirmHandler()}
                />
            </div>
        </OmApplicationPopupModal>
    );
};

export default IncompleteModal;
