import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { navigate } from 'gatsby';
import OmButton from '../../components/forms/OmButton';

import { API_CALL_STATUS } from '@om/redux/reducers/status';
import {
    toggleCallMeBack,
    setNameValue,
    setSurnameValue,
    setContactNumberValue,
    sendCallMeBackInitiate,
    setGtmContextUrl,
    setGtmContextGaId,
} from '@om/redux/actions/easiplusFuneralPlan/callMeBack';
import {
    getCallMeBackDrawerIsOpen,
    getName,
    getSurname,
    getContactNumber,
    getSendCallMeBackStatus,
    getCallMeBackType,
    getCallMeBackButtonText,
    getCallMeBackButtonDisabled,
    getCallMeBackStatusIsSuccess,
    getCallMeBackStatusIsFailure
} from '@om/redux/selectors/easiplusFuneralPlan/callMeBack';

import { CALL_ME_BACK_TYPES } from '@om/redux/reducers/status'
import { DEFAULT_VALIDATION_DELAY } from '../config';
import { OmFormInputFieldWrapper, OmSideDrawer, OmIcon } from '@om/component-library-react';


const initCallMeBackSend = dispatch => {
    let GaId = '0.0';
    try {
        // eslint-disable-next-line no-undef
        const GAClient = ga.getAll();
        if (GAClient && GAClient.length > 0) {
            GaId = GAClient[0].get('clientId');
        }
    } catch (error) {
        console.log(error); //Implementation is the same as the normal CMB
    }

    dispatch(setGtmContextUrl(window.location.href));
    dispatch(setGtmContextGaId(GaId));
    dispatch(sendCallMeBackInitiate());
};

const CallMeBack = () => {
    const formFieldProperties = {
        required: true,
        isSensitive: true,
        size: 'full',
        align: 'left',
        formId: 'efpCallMeBackForm',
    };

    const dispatch = useDispatch();

    const {
        callMeBackDrawerIsOpen,
        name,
        surname,
        contactNumber,
        sendCallMeBackStatus,
        callMeBackType,
        callMeBackButtonText,
        callMeBackButtonDisabled,
        callMeBackStatusIsSuccess,
        callMeBackStatusIsFailure,
    } = useSelector(
        createStructuredSelector({
            callMeBackDrawerIsOpen: getCallMeBackDrawerIsOpen,
            name: getName,
            surname: getSurname,
            contactNumber: getContactNumber,
            sendCallMeBackStatus: getSendCallMeBackStatus,
            callMeBackType: getCallMeBackType,
            callMeBackButtonText: getCallMeBackButtonText,
            callMeBackButtonDisabled: getCallMeBackButtonDisabled,
            callMeBackStatusIsSuccess: getCallMeBackStatusIsSuccess,
            callMeBackStatusIsFailure: getCallMeBackStatusIsFailure,
        })
    );

    useEffect(() => {
        if (sendCallMeBackStatus === API_CALL_STATUS.SUCCESS && callMeBackType === CALL_ME_BACK_TYPES.REPLACEMENT) {
            setTimeout(() => {
                navigate('/personal/solutions/funeral/');
            }, 2000);
        }
    }, [sendCallMeBackStatus, callMeBackType]);

    return (
        <OmSideDrawer
            onOnSideDrawerClosed={() => dispatch(toggleCallMeBack(null))}
            open={callMeBackDrawerIsOpen}
        >
            <div slot="side-drawer-content" className="speak-to-an-adviser">
                <h5 className="no-top-margin"><strong>We’ll get in touch soon</strong></h5>
                <h6 className="no-top-margin">Share your details with us and we'll get in touch as soon as possible.</h6>
                <div className="contact-details">
                    <OmFormInputFieldWrapper
                        {...formFieldProperties}
                        name="name"
                        placeholder="Name"
                        value={name.value}
                        state={name.status}
                        errorMessage={name.error}
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        onPersist={(event) => dispatch(setNameValue(event.detail))}
                    />
                    <br />
                    <OmFormInputFieldWrapper
                        {...formFieldProperties}
                        name="surname"
                        placeholder="Surname"
                        value={surname.value}
                        state={surname.status}
                        errorMessage={surname.error}
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        onPersist={(event) => dispatch(setSurnameValue(event.detail))}
                    />
                    <br />
                    <OmFormInputFieldWrapper
                        {...formFieldProperties}
                        name="mobileNumber"
                        placeholder="Mobile Number"
                        value={contactNumber.displayValue}
                        state={contactNumber.status}
                        errorMessage={contactNumber.error}
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        onPersist={(event) => dispatch(setContactNumberValue(event.detail))}
                    />
                </div>
                <div className="form-submit-details">
                    <OmButton
                        type="primary"
                        size="small"
                        text={callMeBackButtonText}
                        disabled={callMeBackButtonDisabled}
                        onClick={() => initCallMeBackSend(dispatch)}
                    />
                    <div className="marketing-disclaimer">
                        <OmIcon iconName="lock_outline" />
                        <span>Don’t worry, we will not contact you for marketing purposes </span>
                    </div>

                    {callMeBackStatusIsSuccess &&
                        <div className="cmb-success-message">
                            <h5 className="medium sessioncamhidetext">Thank you {name.value}!</h5>
                            <h6 className="no-margin-top">One of our consultants will call you shortly.</h6>
                        </div>
                    }
                    {callMeBackStatusIsFailure &&
                        <div className="cmb-fail-message">
                            <h5 className="medium">Sorry {name.value}!</h5>
                            <h6 className="no-margin-top">Please try again.</h6>
                        </div>
                    }
                </div>
            </div>
        </OmSideDrawer>
    );
};

export default CallMeBack;
