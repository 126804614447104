import React from 'react';
import {
    OmLabeledValueSummary,
    OmEfpFamilyMemberCard,
} from '@om/component-library-react';

const FamilyMemberCard = ({
    member,
    hasDetails,
    canRemove,
    description,
    displayFirstLabelSet,
    displaySecondLabelSet,
    fullName,
    coverAmount,
    monthlyPremium,
    age,
    editCoverMemberHandler,
    removeMemberHandler,
    addCoverMemberHandler,
}) => {
    const getLabels = () => {
        if (displayFirstLabelSet) {
            return (
                <div>
                    <OmLabeledValueSummary
                        isSensitive="true"
                        label="Name & Surname"
                        value={fullName}
                    />
                    <OmLabeledValueSummary
                        label="Cover Amount"
                        value={coverAmount}
                    />
                    <OmLabeledValueSummary
                        label="Monthly Premium"
                        value={monthlyPremium}
                    />
                </div>
            )
        } else if (displaySecondLabelSet) {
            return (
                <div>
                    <OmLabeledValueSummary
                        isSensitive="true"
                        label="Name & Surname"
                        value={fullName}
                    />
                    <OmLabeledValueSummary
                        label="Age"
                        value={age}
                    />
                </div>
            )
        }
    }

    return (
        <OmEfpFamilyMemberCard
            id={member}
            onEditDetailsClicked={() => {
                editCoverMemberHandler();
            }}
            onRemoveMembersClicked={() => {
                removeMemberHandler();
            }}
            onAddDetailsClicked={() => {
                addCoverMemberHandler();
            }}
            editEnabled={hasDetails}
            deleteEnabled={canRemove}
        >
            <h6 slot="family-member-card-heading">{description}</h6>
            <div slot="family-member-card-body">
                {getLabels()}
            </div>
        </OmEfpFamilyMemberCard>
    );
};

export default FamilyMemberCard;
