import React from 'react';
import { useDispatch } from 'react-redux';
import FamilyMemberCard from './FamilyMemberCard';
import {
    setAddCoverMember,
    setEditCoverMember,
    setRemoveCoverMember,
    setModalValue,
} from '@om/redux/actions/easiplusFuneralPlan/livesCovered';
import { EFP_POPUP_MODALS } from '@om/redux/actions/easiplusFuneralPlan/types';
import { OmEfpFamilyMemberCardGroup } from '@om/component-library-react';

const FamilyMemberCardGroup = ({
    complete,
    headingText,
    viewMoreText,
    coverCategoryCodes,
    coverMembers,
    viewMoreHandler
}) => {
    const dispatch = useDispatch();

    const maskCurrency = (value) => {
        return 'R' + Number(value).toLocaleString('en').split(',').join(' ');
    }

    const removeMemberIconClicked = (value) => {
        dispatch(setRemoveCoverMember(value));
        dispatch(setModalValue(EFP_POPUP_MODALS.REMOVE_CONFIRMATION))
    }

    return (
        <OmEfpFamilyMemberCardGroup
            onViewMembers={() => {
                viewMoreHandler();
            }}
            complete={complete}
            headingAlignment="left"
            headingText={headingText}
            buttonText={viewMoreText}
        >
            <div slot="family-member-card-items">
                {coverCategoryCodes.map((member, index) => {
                    return (
                        <FamilyMemberCard
                            key={index}
                            id={member}
                            hasDetails={coverMembers[member].hasDetails}
                            canRemove={coverMembers[member].canRemove}
                            description={coverMembers[member].description}
                            displayFirstLabelSet={coverMembers[member].displayFirstLabelSet}
                            displaySecondLabelSet={coverMembers[member].displaySecondLabelSet}
                            fullName={coverMembers[member].fullName}
                            coverAmount={maskCurrency(coverMembers[member].coverAmount.value)}
                            monthlyPremium={maskCurrency(coverMembers[member].monthlyPremium.value)}
                            age={coverMembers[member].age.value}
                            editCoverMemberHandler={() => dispatch(setEditCoverMember(member))}
                            removeMemberHandler={() => removeMemberIconClicked(member)}
                            addCoverMemberHandler={() => dispatch(setAddCoverMember(member))}>
                        </FamilyMemberCard>
                    )
                })}
            </div>
        </OmEfpFamilyMemberCardGroup>
    );
};

export default FamilyMemberCardGroup;
