import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import LivesCoveredDetailsSideDrawer from './popups/LivesCoveredDetailsSideDrawer';
import IncompleteModal from './popups/IncompleteModal';
import MaxMembersModal from './popups/MaxMemberModal';
import RemoveConfirmationModal from './popups/RemoveConfirmationModal';
import FamilyMemberCardGroup from './FamilyMemberCardGroup';
import { OmQuoteSummaryCard, OmEfpLivesCovered } from '@om/component-library-react';

import { EFP_POPUP_MODALS } from '@om/redux/actions/easiplusFuneralPlan/types';
import {
    setModalValue,
    clearModalValue,
    setViewMoreCategory,
    deleteCoverMember,
} from '@om/redux/actions/easiplusFuneralPlan/livesCovered';
import {
    getCoverMembersForms,
    getCoverCategories,
    getModalValue,
    getQuoteSummaryCategories,
    getQuoteSummaryPremiums,
} from '@om/redux/selectors/easiplusFuneralPlan/livesCovered';

const LivesCoveredPage = () => {
    const dispatch = useDispatch();
    const {
        coverMembers,
        coverCategories,
        popupModalValue,
        quoteSummaryCategories,
        quoteSummaryPremiums
    } = useSelector(
        createStructuredSelector({
            coverMembers: getCoverMembersForms,
            coverCategories: getCoverCategories,
            popupModalValue: getModalValue,
            quoteSummaryCategories: getQuoteSummaryCategories,
            quoteSummaryPremiums: getQuoteSummaryPremiums
        })
    );

    const removeMember = () => {
        dispatch(deleteCoverMember());
        dispatch(setModalValue(null));
    };

    return (
        <OmEfpLivesCovered>
            <h4 slot="heading" className="heading">Now, add details of <strong>the people you want to cover</strong></h4>
            <div slot="content">
                {Object.keys(coverCategories).map((category) => {
                    return (
                        <FamilyMemberCardGroup
                            key={coverCategories[category].description}
                            complete={coverCategories[category].isComplete}
                            headingText={coverCategories[category].description}
                            viewMoreText={coverCategories[category].viewMoreText}
                            coverMembers={coverMembers}
                            coverCategoryCodes={coverCategories[category].codes}
                            viewMoreHandler={() => dispatch(setViewMoreCategory(category))}>
                        </FamilyMemberCardGroup>
                    )}
                )}
                <div className="gap"></div>
            </div>

            {/* figure out why this is always sticky, needs to be invisible unless scrolled out of view */}
            <div slot="quote-summary-card">
                <OmQuoteSummaryCard
                    name="livesCoveredSticky"
                    aboveFooter={true}
                    isSticky={false}
                    productName="funeral plan"
                    coverCategories={quoteSummaryCategories}
                    coverCategoryAmounts={quoteSummaryPremiums}>
                </OmQuoteSummaryCard>
            </div>

            <div slot="side-drawers">
                <LivesCoveredDetailsSideDrawer />
            </div>

            <div slot="modals">
                <IncompleteModal
                    open={popupModalValue === EFP_POPUP_MODALS.INCOMPLETE_MODAL}
                    confirmHandler={() => dispatch(clearModalValue(EFP_POPUP_MODALS.INCOMPLETE_MODAL))}/>
                <MaxMembersModal
                    open={popupModalValue === EFP_POPUP_MODALS.MAX_CHILDREN}
                    confirmHandler={() => dispatch(clearModalValue())}/>
                <RemoveConfirmationModal
                    open={popupModalValue === EFP_POPUP_MODALS.REMOVE_CONFIRMATION}
                    dismissHandler={() => dispatch(clearModalValue())}
                    confirmHandler={() => removeMember()}/>
            </div>
        </OmEfpLivesCovered>
    );
};

export default LivesCoveredPage;
