import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import OmButton from '../../../../components/forms/OmButton';
import ApplicationTooltip from '../../../../components/applications/ApplicationTooltip';
import {
    OmFormDropdownFieldWrapper,
    OmFormInputFieldWrapper,
    OmSideDrawer,
    OmSlider,
    OmLivesCoveredDetailsSideDrawer,
} from '@om/component-library-react'

import { DEFAULT_VALIDATION_DELAY } from '../../../config';
import { COVER_CATEGORIES } from '@om/redux/actions/easiplusFuneralPlan/types';
import {
    pushNewCoverMember,
    cancelCoverMember,
    setTitleValue,
    setFirstNameValue,
    setSurnameValue,
    setDateOfBirthValue,
    setCoverAmountValue,
} from '@om/redux/actions/easiplusFuneralPlan/livesCovered';
import {
    getAddOrEditCoverMember,
    getTitlesForRelationshipTypes,
    getTitle,
    getFirstName,
    getSurname,
    getDateOfBirth,
    getCoverAmount,
    getMonthlyPremium,
    getSliderSteps,
    getHintText,
    getEditCoverCategory,
    getIsEditMode,
    getDobError,
    getMemberDescription,
    getMemberHasCaption
} from '@om/redux/selectors/easiplusFuneralPlan/livesCovered';
import { FIELD_STATUS } from '@om/redux/reducers/status';

const LivesCoveredDetailsSideDrawer = () => {
    const dispatch = useDispatch();
    const {
        coverMember,
        isEditMode,
        referenceTitles,
        title,
        firstName,
        surname,
        dateOfBirth,
        coverAmount,
        monthlyPremium,
        sliderSteps,
        hintText,
        coverCategory,
        dobError,
        description,
        hasCaption
    } = useSelector(
        createStructuredSelector({
            coverMember: getAddOrEditCoverMember,
            isEditMode: getIsEditMode,
            referenceTitles: getTitlesForRelationshipTypes,

            title: getTitle,
            firstName: getFirstName,
            surname: getSurname,
            dateOfBirth: getDateOfBirth,
            coverAmount: getCoverAmount,
            monthlyPremium: getMonthlyPremium,
            sliderSteps: getSliderSteps,
            hintText: getHintText,
            coverCategory: getEditCoverCategory,
            dobError: getDobError,
            description: getMemberDescription,
            hasCaption: getMemberHasCaption,
        })
    );

    const maskCurrency = (value) => Number(value).toLocaleString('en').split(',').join(' ');
    const updateSlider = (value) => {
        if (dateOfBirth.status === FIELD_STATUS.VALID && !dobError) {
            dispatch(setCoverAmountValue(value));
        } else {
            dispatch(setDateOfBirthValue(dateOfBirth.value));
        }
    }

    return (
        <OmSideDrawer
            onOnSideDrawerClosed={() => dispatch(cancelCoverMember())}
            open={coverMember}
            className="lives-covered-side-drawer"
        >
            <div slot="side-drawer-content">
                <OmLivesCoveredDetailsSideDrawer key={description}>
                    <div slot="lives-covered-details-side-drawer">
                        <h5 className="strong no-top-margin side-drawer-header">{description}</h5>
                        {hasCaption && <p className="description">Someone married to you or couples living together (common-law relationship).</p>}
                        <OmFormDropdownFieldWrapper
                            isSensitive
                            disabled={isEditMode}
                            useNativeMobileDropdown
                            required
                            size="small"
                            formId="livesCoveredDetails"
                            placeholder="Title"
                            name="title"
                            align="left"
                            onOnSelect={event => dispatch(setTitleValue(event.detail.id))}
                            value={title.value}
                            state={title.status}
                            errorMessage={title.error}
                            options={ JSON.stringify(referenceTitles.map(option => ({ value: option.code, label: option.description }))) }
                        />
                        <OmFormInputFieldWrapper
                            isSensitive
                            required
                            name="firstName"
                            size="full"
                            align="left"
                            placeholder="First name"
                            validationDelay={DEFAULT_VALIDATION_DELAY}
                            value={firstName.displayValue}
                            state={isEditMode ? 'disabled' : firstName.status}
                            errorMessage={firstName.error}
                            onPersist={(event) => dispatch(setFirstNameValue(event.detail))}
                        />
                        <OmFormInputFieldWrapper
                            isSensitive
                            required
                            name="surname"
                            size="full"
                            align="left"
                            placeholder="Surname"
                            validationDelay={DEFAULT_VALIDATION_DELAY}
                            value={surname.displayValue}
                            state={isEditMode ? 'disabled' : surname.status}
                            errorMessage={surname.error}
                            onPersist={(event) => dispatch(setSurnameValue(event.detail))}
                        />
                        <OmFormInputFieldWrapper
                            isSensitive
                            required
                            labelAlwaysShow
                            hintText={hintText}
                            name="dateOfBirth"
                            size="full"
                            align="left"
                            mask="date"
                            placeholder="dd/mm/yyyy"
                            label="Date of birth"
                            validationDelay={DEFAULT_VALIDATION_DELAY}
                            value={dateOfBirth.value}
                            state={isEditMode ? 'disabled' : dateOfBirth.status}
                            errorMessage={dobError}
                            onPersist={(event) => dispatch(setDateOfBirthValue(event.detail))}
                        />

                        <p>Cover amount: </p>
                        <OmSlider
                            stepString={JSON.stringify(sliderSteps.sliderSteps)}
                            minLabel={sliderSteps.minLabel}
                            maxLabel={sliderSteps.maxLabel}
                            value={coverAmount.value}
                            onSliderChange={(event) => updateSlider(event.detail)}
                        />

                        <div className="cover-premium-container">
                            <div className="cover-premium-left">
                                <h5 className="strong">R{maskCurrency(coverAmount.value)}</h5>
                                <p className="small">Cover amount</p>
                            </div>
                            <div className="cover-premium-right">
                                <h5 className="strong">R{maskCurrency(monthlyPremium.value)}</h5>
                                <p className="small">Total monthly premium</p>
                            </div>
                        </div>

                        {coverCategory === COVER_CATEGORIES.DIRECT_FAMILY.key &&
                            <ApplicationTooltip
                                iconName="information_outline"
                                tooltipContent="By adjusting the slider, <strong>you are updating the cover amount for every member</strong> in the Me & My Direct Family cover category."
                            />
                        }
                        <div className="action-buttons">
                            <OmButton
                                className="cancel-button"
                                size="small"
                                type="text"
                                text="CANCEL"
                                onClick={() => dispatch(cancelCoverMember())}
                            />
                            <OmButton
                                className="save-button"
                                size="small"
                                type="primary"
                                text="SAVE"
                                onClick={() => dispatch(pushNewCoverMember())}
                            />
                        </div>
                    </div>
                </OmLivesCoveredDetailsSideDrawer>
            </div>
        </OmSideDrawer>
    );
};

export default LivesCoveredDetailsSideDrawer;