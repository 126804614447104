import React from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getBeneficiaryListForConfirmDetails } from '@om/redux/selectors/easiplusFuneralPlan/beneficiaries';
import { toggleDeclarations, setDeclarationsDrawer } from '@om/redux/actions/easiplusFuneralPlan/confirmDetails';
import {
    getSplitField01,
    getSplitField02,
    getSplitField03,
    getSplitField04,
    getSplitField05,
    getSplitField06,
    getSplitField07,
    getSplitField08,
    getSplitField09,
    getSplitField10,
} from '@om/redux/selectors/easiplusFuneralPlan/beneficiariesSplit';
import {
    getAccountNumber,
    getDebitOrderDay,
    getPaymentAuthorization,
    getFormattedBankName,
    getFormattedAccountName,
} from '@om/redux/selectors/easiplusFuneralPlan/paymentDetails';
import {
    getFirstNames,
    getSurname,
    getFullName,
    getId,
    getTitle,
    getStreetAddress,
    getPostalAddressSame,
    getMobile,
    getEmail,
    getPostalAddressMethod,
    getPostalAddress,
    getResedentialStreetAddress,
    getAddressLabel,
    getFormattedContactNumber
} from '@om/redux/selectors/easiplusFuneralPlan/personalDetails';
import {
    getDeclarationsValid,
    getDeclarationsInvalid,
    getDeclarationsError,
} from '@om/redux/selectors/easiplusFuneralPlan/confirmDetails';
import {
    getCoverMembersForms,
    getLivesCoveredKeysForSummary,
    getTotalPremium
} from '@om/redux/selectors/easiplusFuneralPlan/livesCovered';
import { getIsConfirmationPage } from '@om/redux/selectors/easiplusFuneralPlan/routing';
import {
    navigateToPersonalDetails,
    navigateToLivesCovered,
    navigateToBeneficiaries,
    navigateToPaymentDetails
} from '@om/redux/actions/easiplusFuneralPlan/routing';
import ApplicationSummaryDetailsCard  from '../../../components/applications/ApplicationSummaryDetailsCard';
import {
    OmApplicationReviewSection,
    OmLabeledValueSummary,
    OmCheckBox,
    OmFormError,
    OmEfpConfirmationScreen,
} from '@om/component-library-react';

const ConfirmDetailsPage = () => {
    const dispatch = useDispatch();

    const selectors = useSelector(
        createStructuredSelector({
            //payment details
            formattedBankName: getFormattedBankName,
            formattedAccountType: getFormattedAccountName,
            accountNumber: getAccountNumber,
            debitOrderDay: getDebitOrderDay,
            paymentAuthorization: getPaymentAuthorization,
            premium: getTotalPremium,
            //personal details
            title: getTitle,
            firstNames: getFirstNames,
            surname: getSurname,
            fullName: getFullName,
            idNumber: getId,
            email: getEmail,
            mobileNumber: getMobile,
            postalAddressSame: getPostalAddressSame,
            addressLabel: getAddressLabel,
            formattedContactNumber: getFormattedContactNumber,
            //address details
            streetAddress: getStreetAddress,
            resedentialStreetAddress: getResedentialStreetAddress,
            //postal address
            postallAddressMethod: getPostalAddressMethod,
            postallAddress: getPostalAddress,
            //lives covered members
            livesCoveredMember: getCoverMembersForms,
            livesCoveredMemberKeys: getLivesCoveredKeysForSummary,
            //beneficiaries
            beneficiaryList: getBeneficiaryListForConfirmDetails,
            splitField01: getSplitField01,
            splitField02: getSplitField02,
            splitField03: getSplitField03,
            splitField04: getSplitField04,
            splitField05: getSplitField05,
            splitField06: getSplitField06,
            splitField07: getSplitField07,
            splitField08: getSplitField08,
            splitField09: getSplitField09,
            splitField10: getSplitField10,
            declarationsChecked: getDeclarationsValid,
            declarationsInvalid: getDeclarationsInvalid,
            declarationErrorMessage: getDeclarationsError,
            isConfirmationPage: getIsConfirmationPage
        })
    );

    return (
        <OmEfpConfirmationScreen>
            <h4 slot="heading" className="no-top-margin no-bottom-margin">
                Lastly, <strong>please confirm your details</strong>
            </h4>

            <ApplicationSummaryDetailsCard headingText="Product summary" slot="summary-card">
                <h6 className="no-top-margin no-bottom-margin total-heading">total monthly premium is:</h6>
                <div className="wrap-text">
                    <h5 className="no-top-margin no-bottom-margin medium">R{selectors.premium} p/m</h5>
                    <h4 className="no-top-margin no-bottom-margin">Total monthly premium</h4>
                </div>
            </ApplicationSummaryDetailsCard>

            <div slot="form">
                <OmApplicationReviewSection titleHeader="Lives Covered">
                    <span slot="edit-link" onClick={() => dispatch(navigateToLivesCovered())}>
                        <h6 className="small"><strong>EDIT</strong></h6>
                    </span>
                    <div slot="section-body">
                        {
                            selectors.livesCoveredMemberKeys.map((coverMemberKey, index) => (
                                <OmLabeledValueSummary
                                    key={index}
                                    is-sensitive="true"
                                    label="Full name"
                                    value={selectors.livesCoveredMember[coverMemberKey].fullName}
                                />
                            ))
                        }
                    </div>
                </OmApplicationReviewSection>

                <OmApplicationReviewSection titleHeader="Personal Details">
                    <span slot="edit-link" onClick={() => dispatch(navigateToPersonalDetails())}>
                        <h6 className="small"><strong>EDIT</strong></h6>
                    </span>
                    <div slot="section-body">
                        <OmLabeledValueSummary
                            label="Title"
                            value={selectors.title.value}
                        />
                        <OmLabeledValueSummary
                            is-sensitive="true"
                            label="Full name"
                            value={selectors.fullName}
                        />
                        <OmLabeledValueSummary
                            is-sensitive="true"
                            label="ID number"
                            value={selectors.idNumber.value}
                        />
                    </div>
                </OmApplicationReviewSection>

                <OmApplicationReviewSection titleHeader="Contact Details">
                    <span slot="edit-link" onClick={() => dispatch(navigateToPersonalDetails())}>
                        <h6 className="small"><strong>EDIT</strong></h6>
                    </span>
                    <div slot="section-body">
                        <OmLabeledValueSummary
                            is-sensitive="true"
                            label="Mobile number"
                            value={selectors.formattedContactNumber} />
                        <OmLabeledValueSummary
                            is-sensitive="true"
                            label="Email"
                            value={selectors.email.value} />
                    </div>
                </OmApplicationReviewSection>

                <OmApplicationReviewSection titleHeader="Address Details">
                    <span slot="edit-link" onClick={() => dispatch(navigateToPersonalDetails())}>
                        <h6 className="small"><strong>EDIT</strong></h6>
                    </span>
                    <div slot="section-body">
                        <OmLabeledValueSummary
                            is-sensitive="true"
                            label={selectors.addressLabel}
                            value={selectors.resedentialStreetAddress}
                        />
                        {
                            !selectors.postalAddressSame.value ?
                                <OmLabeledValueSummary
                                    is-sensitive="true"
                                    label="Postal address"
                                    value={selectors.postallAddress}
                                />
                                : ''
                        }
                    </div>
                </OmApplicationReviewSection>

                {
                    selectors.beneficiaryList.length >= 1 ?
                        <OmApplicationReviewSection titleHeader="Beneficiary Details">
                            <span slot="edit-link" onClick={() => dispatch(navigateToBeneficiaries())}>
                                <h6 className="small"><strong>EDIT</strong></h6>
                            </span>
                            <div slot="section-body">
                                {
                                    selectors.beneficiaryList.map((benefciary, index) => (
                                        <OmLabeledValueSummary
                                            key={index}
                                            is-sensitive={true}
                                            className="beneficiary-name"
                                            label="Full name"
                                            value={benefciary.FullName}
                                        />
                                    ))
                                }
                            </div>
                        </OmApplicationReviewSection>
                        : ''
                }

                <OmApplicationReviewSection titleHeader="Payment Details">
                    <span slot="edit-link" onClick={() => dispatch(navigateToPaymentDetails())}>
                        <h6 className="small"><strong>EDIT</strong></h6>
                    </span>
                    <div slot="section-body">
                        <OmLabeledValueSummary
                            label="Bank name"
                            value={selectors.formattedBankName}
                        />
                        <OmLabeledValueSummary
                            is-sensitive="true"
                            label="Account holder name"
                            value={selectors.fullName}
                        />
                        <OmLabeledValueSummary
                            is-sensitive="true"
                            label="Account number"
                            value={selectors.accountNumber.value}
                        />
                        <OmLabeledValueSummary
                            label="Account type"
                            value={selectors.formattedAccountType}
                        />
                        <OmLabeledValueSummary
                            label="Debit order date"
                            value={selectors.debitOrderDay.value + ' of every month'}
                        />
                    </div>
                </OmApplicationReviewSection>

                <div className="check-box-container">
                    <OmCheckBox
                        checked={selectors.declarationsChecked}
                        onOnCheckBoxChanged={() => dispatch(toggleDeclarations())}
                    >
                        <p slot="text-slot">
                            I accept the&nbsp;
                            <button onClick={() => dispatch(setDeclarationsDrawer(true))}>
                                declarations.
                            </button>
                        </p>
                    </OmCheckBox>
                    <OmFormError message={selectors.declarationErrorMessage}></OmFormError>
                </div>
            </div>
        </OmEfpConfirmationScreen>
    );
};

export default ConfirmDetailsPage;
