import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {setSideDrawersClosed} from '@om/redux/actions/easiplusFuneralPlan/confirmDetails';
import {getDeclarationsSideDrawerOpen} from '@om/redux/selectors/easiplusFuneralPlan/confirmDetails';
import { OmSideDrawer } from '@om/component-library-react';

const DeclarationDrawer = () => {
    const dispatch = useDispatch();
    
    const { drawerOpen } = useSelector(
        createStructuredSelector({
            drawerOpen: getDeclarationsSideDrawerOpen
        })
    );

    return (
        <OmSideDrawer
            open={drawerOpen}
            onOnSideDrawerClosed={() => dispatch(setSideDrawersClosed())}>
            <div slot="side-drawer-content">
                <h5 className="no-top-margin"><strong>Declarations</strong></h5>
                <section>
                    <p>
                        <small>
                            <strong>Old Mutual Life Assurance Company (SA) is a licensed Financial Services Provider and Life Insurer and holds Professional Indemnity Insurance</strong>
                        </small>
                    </p>
                    <p>
                        <small>
                            <strong>I have read and understood the product information given to me during this application.</strong>
                        </small>
                    </p>
                </section>

                <section>
                    <small>
                        I <strong>understand and agree</strong> that: 
                        <ul>
                            <li>All the information I have provided to Old Mutual in this application is true and correct.</li>
                            <li>This is only an application. Old Mutual will evaluate this application and may accept or refuse 
                                it.</li>
                            <li>I acknowledge that when Old Mutual evaluates my application, Old Mutual may accept or adjust the 
                                cover that I have chosen.</li>
                            <li>If Old Mutual accepts my application, they will send me an English policy contract.</li>
                            <li>I understand that this funeral plan has a six-month waiting period from the application date or 
                                date on which an insured person was added under the plan. During this waiting period, Old Mutual 
                                will only pay the cover if the insured person dies because of an accident. However, if the funeral 
                                plan I am claiming on was taken out within two months of a previous policy that was designed to 
                                cover funeral expenses being cancelled (including because it had lapsed or its term had ended), 
                                the waiting period may be reduced. I also understand that the waiting period will not be reduced 
                                if the previous policy was cancelled but cover under it had not ended when an insured person dies. 
                                If I applied for more cover under this plan than I had under the previous policy, Old Mutual will 
                                not reduce the waiting period on the increased portion of cover.</li>
                            <li>You have 30 days to opt out the contract from date of receiving the policy contract at no cost to you.</li>
                            <li>The cover will be changed if Old Mutual at claims stage discovers that the date of birth I provided 
                                for any insured person differs from his/her date of birth on the death certificate or his/her ID 
                                document. It is important to ensure that the date of birth for all insured persons are correct and 
                                to notify Old Mutual of any changes.</li>
                            <li>The premiums will increase on 1 July each year but the cover will stay the same. If I don’t pay 
                                the increased premium, the cover will reduce by the same portion as the premium should have increased.</li>
                            <li>I may cancel this Funeral Plan but will not get any money because this product does not have any withdrawal
                                 or cancellation value.</li>
                            <li>I must tell Old Mutual if any of my personal details change or the details of any beneficiaries or the 
                                premium payer (if different).</li>
                            <li>Old Mutual needs access to my personal information to evaluate any application for insurance I make for 
                                underwriting purposes and to consider a claim for benefits. If Old Mutual doesn’t have access to this 
                                information, it cannot evaluate my application and consider claims. I also understand that my personal 
                                information may be shared with other insurers to enable all insurers to consider applications for insurance 
                                that I make for underwriting and claims purposes. I understand that my personal information is protected 
                                by law and will be treated confidentially. I authorise a doctor, hospital or any other person to provide 
                                the information that Old Mutual may need.</li>
                            <li>I understand that legal limits also apply to insured persons younger than 14 and Old Mutual has set its 
                                own limit relating to stillborn children. I am aware that at the time of death, Old Mutual will never 
                                pay more than what is allowed by law.</li>
                            <li>I understand replacing any insurance policy for this product may be to my disadvantage and that I should 
                                preferably speak to a qualified financial adviser before doing so.</li>
                            <li>When paying for my policy :<br/>
                                <ul>
                                    <li>I give Old Mutual permission to arrange for the payment of the premiums with my bank.</li>
                                    <li>Premium increases and up to two outstanding premiums may be included in these deductions.</li>
                                    <li>Premiums for an Old Mutual Group Schemes policy will show on my bank statement as “OMGPSCHEME”.</li>
                                    <li>The bank will deduct the premium on the day that I have chosen, or where this is not possible, the 
                                        soonest date thereafter.</li>
                                    <li>Old Mutual will use an early debit order service provided by certain banks to collect premiums when 
                                        there is not enough money in my account on the date that I have chosen. This service allows Old Mutual 
                                        to track my bank account and to deduct my premiums when there is enough money in my account. Any 
                                        premium for an Old Mutual Group Schemes policy that is deducted with the early debit order service 
                                        will show on my bank statement as “OLD MUTUAL”.</li>
                                </ul>
                            </li>
                            
                            <li> I <strong>declare</strong> that: <br/>
                                <ul>
                                    <li>All insured persons on this application are permanent residents of the Republic of South Africa.</li>
                                    <li>My current financial position makes it possible for me to pay the monthly premiums for the 
                                        product/products I am applying for.</li>
                                    <li>I am responsible for the funeral costs of all the insured persons on this application.</li>
                                    <li>I confirm that all information provided is true and complete. I understand that if I misstated or 
                                        failed to give certain facts, Old Mutual may keep the premiums I have paid and I will not receive 
                                        any benefits.</li>
                                </ul>
                            </li>
                            <li>All transactions are recorded and can be made available upon request.</li>
                            <li>For Complaints process and options please visit our website: https://www.oldmutual.co.za/customer-complaints/.</li>
                        </ul>
    
                        <strong>Protection of Personal Information (PPI)</strong>
                        <br/><br/>
                        The Old Mutual Group would like to offer you ongoing financial services and may use your personal information to 
                        provide you with information about products or services that may be suitable to meet your financial needs.  Please 
                        sms your ID number to 30994 if you would prefer not to receive such information and/or financial services. 
                        <br/><br/>

                        We may use your information or obtain information about you for the following purposes:
                        <ul>
                            <li>Underwriting</li>
                            <li>Assessment and processing of claims</li>
                            <li>Credit searches or verification of personal information</li>
                            <li>Claims checks (Life & Claims Register)</li>
                            <li>Tracing beneficiaries</li>
                            <li>Fraud prevention and detection</li>
                            <li>Market research and statistical analysis</li>
                            <li>Audit & record keeping purposes</li>
                            <li>Compliance with legal & regulatory requirements</li>
                            <li>Verifying your identity</li>
                            <li>Sharing information with service providers we engage to process such information on our behalf or who 
                                render services to us. These service providers may be abroad, but we will not share your information 
                                with them unless we are satisfied that they have adequate security measures in place to protect your 
                                personal information.</li>

                        </ul>
                        <br/>
                        You may access your personal information that we hold and may also request us to correct any errors or to delete 
                        this information. In certain cases you have the right to object to the processing of your personal information.
                        <br/>

                        You also have the right to complain to the Information Regulator, whose contact details are:
                        <br/><br/>
                        <ul>
                            <li>http://www.justice.gov.za/inforeg/index.html</li>
                            <li>General enquiries: inforeg@justice.gov.za</li>
                            <li>Complaints: complaints.IR@justice.gov.za</li>
                        </ul>
                        To view our full privacy notice, please visit our website on www.oldmutual.co.za
                    </small>
                </section>
            </div>
        </OmSideDrawer>
    );
};

export default DeclarationDrawer;
