import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link, navigate } from 'gatsby';

import OmButton from '../../components/forms/OmButton';
import { 
    Om1ColLayout, 
    OmIcon,
    OmApplicationWizardHeader,
    OmApplicationProgressBar
} from '@om/component-library-react';


import { resetQuote } from '@om/redux/actions/easiplusFuneralPlan/quote';
import { resetReplacementPolicy } from '@om/redux/actions/easiplusFuneralPlan/replacementPolicy';
import { resetPersonalDetails } from '@om/redux/actions/easiplusFuneralPlan/personalDetails';
import { resetLivesCovered } from '@om/redux/actions/easiplusFuneralPlan/livesCovered';
import { resetBeneficiaries } from '@om/redux/actions/easiplusFuneralPlan/beneficiaries';
import { resetBeneficiariesSplit } from '@om/redux/actions/easiplusFuneralPlan/beneficiariesSplit';
import { resetPaymentDetails } from '@om/redux/actions/easiplusFuneralPlan/paymentDetails';
import { resetConfirmDetails } from '@om/redux/actions/easiplusFuneralPlan/confirmDetails';
import { resetCallMeBack } from '@om/redux/actions/easiplusFuneralPlan/callMeBack';
import { resetScroll } from '@om/redux/actions/easiplusFuneralPlan/scrolling';
import { resetRouting } from '@om/redux/actions/easiplusFuneralPlan/routing';
import { resetReferenceData } from '@om/redux/actions/easiplusFuneralPlan/referenceData';
import { toggleCallMeBack } from '@om/redux/actions/easiplusFuneralPlan/callMeBack';
import {
    getHideDesktop,
    getHideMobile,
    getProgressBarIndex
} from '@om/redux/selectors/easiplusFuneralPlan/routing';
import { CALL_ME_BACK_TYPES } from '@om/redux/reducers/status';
import { getSkipConfirmationPage } from '@om/redux/selectors/easiplusFuneralPlan/confirmDetails';
import { PRODUCT_DETAIL_URL } from '@om/redux/actions/easiplusFuneralPlan/types';

const progressBarSteps = (shouldSkipConfirmationPage) => {
    if(shouldSkipConfirmationPage){
        return [
            'Personal Details',
            'Lives Covered',
            'Beneficiaries',
            'Payment details',
        ].join('|');
    }
    return [
        'Personal Details',
        'Lives Covered',
        'Beneficiaries',
        'Payment details',
        'Confirmation'
    ].join('|');
}

const ApplicationWizardHeader = (mobileHeaderTexture) => {
    const dispatch = useDispatch();
    const {
        hideDesktop,
        hideMobile,
        progressBarIndex,
        skipConfirmationPage
    } = useSelector(
        createStructuredSelector({
            hideDesktop: getHideDesktop,
            hideMobile: getHideMobile,
            progressBarIndex: getProgressBarIndex,
            skipConfirmationPage: getSkipConfirmationPage
        })
    );

    const closeButtonClick = () => {
        navigate(PRODUCT_DETAIL_URL);
        dispatch(resetQuote());
        dispatch(resetReplacementPolicy());
        dispatch(resetPersonalDetails());
        dispatch(resetLivesCovered());
        dispatch(resetBeneficiaries());
        dispatch(resetBeneficiariesSplit());
        dispatch(resetPaymentDetails());
        dispatch(resetConfirmDetails());
        dispatch(resetCallMeBack());
        dispatch(resetScroll());
        dispatch(resetRouting());
        dispatch(resetReferenceData());
    };

    return (
        <OmApplicationWizardHeader
            helpNumber="0860 22 52 46"
            headerTexture={ mobileHeaderTexture ? mobileHeaderTexture.mobileHeaderTexture.url : '' }
        >
            <div slot="wizard-title">
                <h5><strong>Easiplus Funeral Plan</strong></h5>
            </div>

            <OmButton
                slot="contact-button"
                type="secondary"
                size="small"
                text="Call me back"
                onClick={ () => dispatch(toggleCallMeBack(CALL_ME_BACK_TYPES.GENERIC)) }
            />

            <Link slot="close-button" to={PRODUCT_DETAIL_URL}>
                <OmIcon className="close-icon" iconName="close" onClick={() => closeButtonClick()} />
            </Link>

            <div slot="progress-bar">
                <Om1ColLayout className="header">
                    <OmApplicationProgressBar
                        applicationName="Easiplus Funeral Plan"
                        steps={progressBarSteps(skipConfirmationPage)}
                        index={progressBarIndex}
                        closable={ true }
                        hideDesktop={ hideDesktop }
                        hideMobile={ hideMobile }
                    >
                        <OmButton
                            actionType="Default"
                            slot="close-application"
                            type="text"
                            size="small"
                            text="CLOSE"
                            onClick={() => closeButtonClick()}
                        />
                    </OmApplicationProgressBar>
                </Om1ColLayout>
            </div>
        </OmApplicationWizardHeader>
    );
};

export default ApplicationWizardHeader;
