import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {getBeneficiaryList} from '@om/redux/selectors/easiplusFuneralPlan/beneficiaries';
import {
    setSplitPercentageValue,
    selectEvenSplit,
    deselectEvenSplit,
    discardEvenSplit,
} from '@om/redux/actions/easiplusFuneralPlan/beneficiariesSplit';
import {
    getBeneficiaryListError,
    getIsEvenSplit,
    getSplitError,
    getTotalPercentage,
    getSplitFieldArray,
} from '@om/redux/selectors/easiplusFuneralPlan/beneficiariesSplit';

import {
    OmFormInputFieldWrapper,
    OmApplicationBeneficiaryList,
    OmApplicationBeneficiarySplitCard,
    OmEfpBeneficiaries,
} from '@om/component-library-react';


// Custom input field is being used, as the error message must not be displayed by the input field
// But instead directly in the om-application-beneficiary-split-card (see error-message prop)
const OmFormInputField = ({
    index,
    maxLength,
    value,
    state,
    persistHandler
}) => {
    return (
        <OmFormInputFieldWrapper
            slot="percentage-input"
            placeholder=""
            required
            size="full"
            align="center"
            keyboardMode="numeric"
            id={'percentage-field-' + (index+1)}
            name={'percentage-field-' + (index+1)}
            value={value}
            state={state}
            maxlength={maxLength}
            onPersist={ (event) => {persistHandler(event.detail);} }
        />
    )
}
const BeneficiariesPage = () => {
    const dispatch = useDispatch();
    const {
        beneficiaryList,
        beneficiaryListStatus,
        totalPercentage,
        splitError,
        isEvenSplit,
        splitFields
    } = useSelector(
        createStructuredSelector({
            beneficiaryList: getBeneficiaryList,
            beneficiaryListStatus: getBeneficiaryListError,
            totalPercentage: getTotalPercentage,
            splitError: getSplitError,
            isEvenSplit: getIsEvenSplit,
            splitFields: getSplitFieldArray,
        })
    );

    const checkBoxChanged = () => {
        if (isEvenSplit) {
            dispatch(deselectEvenSplit());
            dispatch(discardEvenSplit());
            return;
        }
        dispatch(selectEvenSplit());
    };

    const persistHandler = (detail, id) => {
        dispatch(setSplitPercentageValue(detail, id));
    };

    return (
        <OmEfpBeneficiaries>
            <div>
                <h5 className="no-top-margin">
                    How would you like to <span className="strong">split the payout? </span>
                </h5>
                <OmApplicationBeneficiaryList
                    onCheckBoxChanged = {checkBoxChanged}
                    evenSplit={isEvenSplit}
                    totalPercentage={totalPercentage}
                    errorMessage={splitError}
                    state={beneficiaryListStatus}>
                    <div slot="beneficiary-cards">
                        {beneficiaryList.map((beneficiary, index) => {
                            return (
                                <OmApplicationBeneficiarySplitCard
                                    key={index}
                                    number={index + 1}
                                    name={beneficiary.value.firstName.value}
                                    surname={beneficiary.value.surname.value}
                                    errorMessage={splitFields[index].error}>
                                    <OmFormInputField
                                        index={index}
                                        maxLength={2}
                                        value={splitFields[index].value}
                                        state={splitFields[index].status}
                                        persistHandler={(detail) => persistHandler(detail, beneficiary.id)}
                                    />
                                </OmApplicationBeneficiarySplitCard>
                            );
                        })}
                    </div>
                </OmApplicationBeneficiaryList>
            </div>
        </OmEfpBeneficiaries>
    );
};

export default BeneficiariesPage;