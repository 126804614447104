import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getScrollToId } from '@om/redux/selectors/easiplusFuneralPlan/scrolling';
import { toggleCallMeBack } from '@om/redux/actions/easiplusFuneralPlan/callMeBack';
import { setSkipConfirmationPageValue } from '@om/redux/actions/easiplusFuneralPlan/confirmDetails';
import { setIsReplacementPolicyValue } from '@om/redux/actions/easiplusFuneralPlan/replacementPolicy';
import {
    getIsReplacementPolicy,
    getIsReplacmentPolicyValue,
} from '@om/redux/selectors/easiplusFuneralPlan/replacementPolicy';
import {
    navigateToPersonalDetails,
    navigateNext,
} from '@om/redux/actions/easiplusFuneralPlan/routing';
import { CALL_ME_BACK_TYPES } from '@om/redux/reducers/status';
import {
    OmEfpReplacementPolicy,
    OmRadioChipButtonGroup,
    OmApplicationCard,
} from '@om/component-library-react';
import OmButton from '../../../components/forms/OmButton';

const ReplacementPolicyPage = ({ excludeConfirmDetailsPage }) => {
    const dispatch = useDispatch();
    const { isReplacementPolicy, isReplacementPolicyValue } = useSelector(
        createStructuredSelector({
            scrollToId: getScrollToId,
            isReplacementPolicy: getIsReplacementPolicy,
            isReplacementPolicyValue: getIsReplacmentPolicyValue,
        })
    );

    // if exclude_confirm_details_page is set to true, dispatch setSkipConfirmationPageValue with true
    useEffect(() => {
        if (excludeConfirmDetailsPage) {
            dispatch(setSkipConfirmationPageValue(true));
        }
    }, []);

    return (
        <OmEfpReplacementPolicy>
            <h3 slot="heading" className="no-top-margin no-bottom-margin">
                Before we continue
            </h3>
            <p slot="subheading">
                Have you cancelled, stopped paying premiums, changed or made any financial product
                including funeral paid up or are you planning to do so?
            </p>
            <div slot="form">
                <section className="form-section">
                    <OmRadioChipButtonGroup
                        value={isReplacementPolicyValue}
                        onOnSelection={(event) => {
                            dispatch(setIsReplacementPolicyValue(event.detail === 'true'));
                            if (event.detail === 'false') dispatch(navigateNext());
                        }}
                    >
                        <ul slot="list-items">
                            <li value={true}>YES</li>
                            <li value={false}>NO</li>
                        </ul>
                    </OmRadioChipButtonGroup>
                    {isReplacementPolicy.value && (
                        <OmApplicationCard>
                            <div slot="card-content">
                                <h6 className="medium no-top-margin no-bottom-margin">
                                    Would you like an advisor to call you back?
                                </h6>
                                <p>
                                    <small>
                                        Replacement may be to your disadvantage as you may not be
                                        able to get cover for all the insured persons for the same
                                        premiums that you previously paid and the EasiPlus Funeral
                                        Plan may have more exclusions, restrictions or waiting
                                        periods, especially if your health has changed.
                                        <br />
                                        <br />
                                        Because we cannot give you advice, you must consider the
                                        information provided online to you and decide for yourself
                                        whether the product will meet your needs or see an adviser
                                        who can help you with your decision and give you advice if
                                        you need it.  We recommend that you see an adviser if the
                                        replaced product is an Old Mutual product.
                                    </small>
                                </p>
                                <br />
                                <OmButton
                                    text="Continue"
                                    type="primary"
                                    size="small"
                                    onClick={() => dispatch(navigateToPersonalDetails())}
                                ></OmButton>
                                <span className="mobile-line-break">
                                    <br />
                                </span>
                                <OmButton
                                    text="Call me back"
                                    type="secondary"
                                    size="small"
                                    onClick={() =>
                                        dispatch(toggleCallMeBack(CALL_ME_BACK_TYPES.REPLACEMENT))
                                    }
                                ></OmButton>
                            </div>
                        </OmApplicationCard>
                    )}
                    <span id="continue-button-anchor" />
                </section>
            </div>
        </OmEfpReplacementPolicy>
    );
};

export default ReplacementPolicyPage;
