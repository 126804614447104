import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {graphql, navigate} from 'gatsby';
import {Router, useLocation} from '@reach/router';
import { useSessionCamVariables } from '../hooks/useSessionCamVariables';

import { APP_NOT_INITIATED, PAGE_URLS, PRODUCT_DETAIL_URL, PAGES } from '@om/redux/actions/easiplusFuneralPlan/types';
import { resetScroll } from '@om/redux/actions/easiplusFuneralPlan/scrolling';
import { getScrollToId } from '@om/redux/selectors/easiplusFuneralPlan/scrolling';
import { setReplacementProductValue, setGenericProductValue } from '@om/redux/actions/easiplusFuneralPlan/callMeBack';
import { setActivePageIndex } from '@om/redux/actions/easiplusFuneralPlan/routing';
import { getActivePageIndex } from '@om/redux/selectors/easiplusFuneralPlan/routing';
import { getRequestId } from '@om/redux/selectors/easiplusFuneralPlan/sessionCamVariables';

import { OmApplicationPage } from '@om/component-library-react';
import ApplicationLayout from '../layouts/ApplicationLayout';
import ApplicationWizardFooter from '../applications/easiplusFuneralPlan/ApplicationWizardFooter';
import ApplicationWizardHeader from '../applications/easiplusFuneralPlan/ApplicationWizardHeader';
import BeneficiariesPage from '../applications/easiplusFuneralPlan/beneficiariesPage/BeneficiariesPage';
import BeneficiariesSplitPage from '../applications/easiplusFuneralPlan/beneficiariesSplitPage/BeneficiariesSplitPage';
import ConfirmDetailsPage from '../applications/easiplusFuneralPlan/confirmDetailsPage/ConfirmDetailsPage';
import LivesCoveredPage from '../applications/easiplusFuneralPlan/livesCoveredPage/LivesCoveredPage';
import PaymentDetailsPage from '../applications/easiplusFuneralPlan/paymentDetailsPage/PaymentDetailsPage';
import PersonalDetailsPage from '../applications/easiplusFuneralPlan/personalDetailsPage/PersonalDetailsPage';
import ReplacementPolicyPage from '../applications/easiplusFuneralPlan/replacementPolicyPage/ReplacementPolicyPage';
import ThankYouPage from '../applications/easiplusFuneralPlan/thankYouPage/ThankYouPage';
import CallMeBack from '../applications/easiplusFuneralPlan/CallMeBack';
import DeclarationDrawer from '../applications/easiplusFuneralPlan/DeclarationDrawer';

const EfpApplicationPage = ({ data }) => {
    const [isRouteCorrect, setRouteCorrect] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();

    const {
        mobile_header_texture,
        thank_you,
        thank_you_four_column_layout,
        thank_you_three_column_layout,
        call_me_back_replacement,
        call_me_back_generic,
        include_avs,
        include_cdv,
        exclude_confirm_details_page
    } = data.contentstackEasiplusFuneralPlanApplication;

    const { activePageIndex, scrollToId, requestId } = useSelector(
        createStructuredSelector({
            activePageIndex: getActivePageIndex,
            scrollToId: getScrollToId,
            requestId: getRequestId
        })
    );

    useEffect(() => {
        dispatch(setReplacementProductValue(call_me_back_replacement[0].call_me_back_product));
        dispatch(setGenericProductValue(call_me_back_generic[0].call_me_back_product));
    }, [call_me_back_replacement, call_me_back_generic, dispatch])

    useEffect(() => {
        const currentPageIndex = PAGES.map(page => page.URL).indexOf(location.pathname);
        if (currentPageIndex !== activePageIndex) {
            if (activePageIndex === APP_NOT_INITIATED) {
                navigate(PRODUCT_DETAIL_URL);
            } else {
                navigate(PAGES[activePageIndex].URL);
                dispatch(setActivePageIndex(activePageIndex));
            }
        } else {
            setRouteCorrect(true)
        }
    }, [activePageIndex, location.pathname, dispatch])

    useSessionCamVariables(requestId);

    if (!isRouteCorrect) return <></>;
    return (
        <ApplicationLayout>
            <OmApplicationPage
                onScrollEnd={() => {
                    dispatch(resetScroll());
                }}
                scrollToId={scrollToId}>
                <div slot="header">
                    <ApplicationWizardHeader mobileHeaderTexture={mobile_header_texture} />
                </div>

                <div slot="content-no-sidebar">
                    <Router>
                        <ReplacementPolicyPage
                            path={PAGE_URLS.REPLACEMENT_POLICY}
                            excludeConfirmDetailsPage={exclude_confirm_details_page}
                        />
                        <PersonalDetailsPage path={PAGE_URLS.PERSONAL_DETAILS} />
                        <LivesCoveredPage path={PAGE_URLS.LIVES_COVERED} />
                        <BeneficiariesPage path={PAGE_URLS.BENEFICIARIES} />
                        <BeneficiariesSplitPage path={PAGE_URLS.BENEFICIARIES_SPLIT} />
                        <PaymentDetailsPage
                            path={PAGE_URLS.PAYMENT_DETAILS}
                            includeAvs={include_avs}
                            includeCdv={include_cdv}
                        />
                        <ConfirmDetailsPage
                            path={PAGE_URLS.CONFIRMATION} />
                        <ThankYouPage
                            path={PAGE_URLS.THANK_YOU}
                            thankYouEntry={thank_you}
                            threeColumnLayout={thank_you_three_column_layout}
                            fourColumnLayout={thank_you_four_column_layout}
                            callMeBack={call_me_back_generic}
                        />
                    </Router>
                </div>

                <div slot="side-drawer">
                    <CallMeBack />
                    <DeclarationDrawer />
                </div>

                <div slot="footer">
                    <ApplicationWizardFooter />
                </div>
            </OmApplicationPage>
        </ApplicationLayout>
    );
};

export const pageQuery = graphql`
    query ModularEfpApplicationPageQuery($id: String!) {
        contentstackEasiplusFuneralPlanApplication(id: { eq: $id }) {
            uid
            title
            mobile_header_texture {
                ...File
            }
            thank_you {
                ...ThankYou
            }
            thank_you_four_column_layout {
                ...FourColumnIllustration
            }
            thank_you_three_column_layout {
                ...ThreeColumnIllustration
            }
            call_me_back_replacement {
                ...CallMeBack
            }
            call_me_back_generic {
                ...CallMeBack
            }
            include_avs
            include_cdv
            exclude_confirm_details_page
        }
    }
`;

export default EfpApplicationPage;
