import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteBeneficiary } from '@om/redux/actions/easiplusFuneralPlan/beneficiaries';
import OmButton from '../../../components/forms/OmButton';
import {
    OmApplicationPopupModal,
    OmIcon,
} from '@om/component-library-react';

const RemoveBeneficiaryModal = () => {
    const dispatch = useDispatch();
    return (
        <OmApplicationPopupModal
            name="removeModal"
            dismissible={true}
        >
            <div slot="content">
                <OmIcon imageUrl="https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/bltc34f1c6e32fb7291/5ec3d2d2a4bead4101d1941f/TrashSVG.svg" />
                <p>Are you sure you want to remove this beneficiary?</p>
            </div>
            <OmButton
                slot="button"
                className="cancel-button"
                type='primary'
                size='small'
                text='YES, REMOVE'
                onClick={() => dispatch(deleteBeneficiary())}
            />
        </OmApplicationPopupModal>
    );
};

export default RemoveBeneficiaryModal;