import React from 'react';
import OmButton from '../../../../components/forms/OmButton';
import { OmApplicationPopupModal, OmIcon } from '@om/component-library-react';

const SuccessModal = ({ slot, open, buttonHandler }) => {
    return (
        <OmApplicationPopupModal
            slot={slot}
            name="successModal"
            dismissible={true}
            open={open}
        >
            <div slot="content">
                <OmIcon
                    imageUrl="https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/blt801513b7ef009be6/5f2935c8dfac843bffcdecfa/SUCCESS_CMOS.svg"
                />
                <h5 className="no-bottom-margin">All good to go!</h5>
            </div>
            <OmButton
                slot="button"
                type='primary'
                size='large'
                text='Okay, thanks'
                onClick={() => buttonHandler()}
            />
        </OmApplicationPopupModal>
    );
};

export default SuccessModal;