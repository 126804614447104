import React from 'react';
import { OmApplicationSummaryDetailsCard } from '@om/component-library-react';

const ApplicationSummaryDetailsCard = ({headingText, slot, children}) => {

    return (
        <OmApplicationSummaryDetailsCard
            headingText={headingText}
            slot={slot}
        >
            <div slot="content">
                {children}
            </div>
        </OmApplicationSummaryDetailsCard>
    );

};

export default ApplicationSummaryDetailsCard;
