import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getEditDrawerIsOpen } from '@om/redux/selectors/easiplusFuneralPlan/beneficiaries';
import {
    cancelEditOfBeneficiary,
    saveBeneficiary,
    setDateOfBirthValue,
    setEmailValue,
    setFirstNameValue,
    setRelationshipValue,
    setMobileValue,
    setSurnameValue,
    setTitleValue,
    toggleEditDrawer,
    setSelectedExistingBeneficiaryValue,
} from '@om/redux/actions/easiplusFuneralPlan/beneficiaries';
import {
    getEditBeneficiaryFormIsValid,
    getTitle,
    getFirstName,
    getSurname,
    getDateOfBirth,
    getRelationship,
    getMobileNumber,
    getEmail,
    getBeneficiaryEditId,
    getLivesCoveredMembersBeneficiaries,
    getRelationshipsBeneficiaries,
    getExistingCoveredMemberUsed,
    getSelectedExistingBeneficiary,
} from '@om/redux/selectors/easiplusFuneralPlan/beneficiaries';
import { getBeneficiaryRelationshipTitles } from '@om/redux/selectors/easiplusFuneralPlan/beneficiaries';
import { DEFAULT_VALIDATION_DELAY } from '../../config';
import OmButton from '../../../components/forms/OmButton';
import { FORM_STATUS } from '@om/redux/reducers/status';

import {
    OmSideDrawer,
    OmApplicationBeneficiaryDetails,
    OmMobileInputFieldWrapper,
    OmFormDropdownFieldWrapper,
    OmFormInputFieldWrapper,
} from '@om/component-library-react';

const BeneficiariesDetailsSideDrawer = () => {
    const dispatch = useDispatch();
    const {
        editDrawerIsOpen,
        editBeneficiaryFormIsValid,
        editId,

        title,
        firstName,
        surname,
        dateOfBirth,
        relationship,
        mobileNumber,
        email,

        livesCoveredMembers,
        relationships,
        isExistingMember,
        selectedExistingBeneficiary,
        beneficiaryRelationshipTitles,
    } = useSelector(
        createStructuredSelector({
            editDrawerIsOpen: getEditDrawerIsOpen,
            editBeneficiaryFormIsValid: getEditBeneficiaryFormIsValid,
            editId: getBeneficiaryEditId,

            title: getTitle,
            firstName: getFirstName,
            surname: getSurname,
            dateOfBirth: getDateOfBirth,
            relationship: getRelationship,
            mobileNumber: getMobileNumber,
            email: getEmail,

            livesCoveredMembers: getLivesCoveredMembersBeneficiaries,
            relationships: getRelationshipsBeneficiaries,
            isExistingMember: getExistingCoveredMemberUsed,
            selectedExistingBeneficiary: getSelectedExistingBeneficiary,
            beneficiaryRelationshipTitles: getBeneficiaryRelationshipTitles,
        })
    );

    const sideDrawerClosedHandler = () => {
        dispatch(toggleEditDrawer());
        dispatch(cancelEditOfBeneficiary());
    };

    const mobilePersistHandler = (event) => {
        dispatch(setMobileValue(event.detail));
    };

    const saveAndCloseBeneficiary = () => {
        if (editBeneficiaryFormIsValid) {
            dispatch(toggleEditDrawer());
        }

        dispatch(saveBeneficiary(editId));
    };

    const cancelAndCloseEditing = () => {
        dispatch(toggleEditDrawer());
        dispatch(cancelEditOfBeneficiary());
    };

    return (
        <OmSideDrawer
            onOnSideDrawerClosed={() => sideDrawerClosedHandler()}
            open={editDrawerIsOpen}
        >
            <div slot="side-drawer-content">
                <OmApplicationBeneficiaryDetails>
                    <div slot="om-application-beneficiary-details">
                        <div>
                            <h5 className="no-top-margin side-drawer-header">
                                {editId ? (
                                    <strong> Edit beneficiary details </strong>
                                ) : (
                                    <strong> Add beneficiary details </strong>
                                )}
                            </h5>
                            {editId == null && livesCoveredMembers.length > 1 && (
                                <OmFormDropdownFieldWrapper
                                    isSensitive
                                    className="inline-field"
                                    useNativeMobileDropdown
                                    required
                                    size="small"
                                    formId="beneficiaryDetails"
                                    placeholder="Select existing cover member"
                                    displayLabel="false"
                                    name="existingCoverMember"
                                    align="left"
                                    onOnSelect={(event) =>
                                        dispatch(
                                            setSelectedExistingBeneficiaryValue(event.detail.id)
                                        )
                                    }
                                    value={selectedExistingBeneficiary}
                                    options={
                                        livesCoveredMembers &&
                                        JSON.stringify(
                                            livesCoveredMembers.map((option) => ({
                                                value: option.code,
                                                label: option.name,
                                                disabled: option.isDisabled,
                                            }))
                                        )
                                    }
                                />
                            )}
                            <OmFormDropdownFieldWrapper
                                isSensitive
                                className="inline-field"
                                useNativeMobileDropdown
                                required
                                size="small"
                                formId="beneficiaryDetails"
                                placeholder="Title"
                                name="title"
                                align="left"
                                disabled={isExistingMember}
                                onOnSelect={(event) => dispatch(setTitleValue(event.detail.id))}
                                value={title.value}
                                state={title.status}
                                errorMessage={title.error}
                                options={
                                    beneficiaryRelationshipTitles &&
                                    JSON.stringify(
                                        beneficiaryRelationshipTitles.map((option) => ({
                                            value: option.code,
                                            label: option.description,
                                        }))
                                    )
                                }
                            />
                            <OmFormInputFieldWrapper
                                isSensitive
                                required
                                formId="beneficiaryDetails"
                                size="full"
                                align="left"
                                name="firstName"
                                placeholder="First name(s)"
                                validationDelay={DEFAULT_VALIDATION_DELAY}
                                value={firstName.displayValue}
                                state={isExistingMember ? 'disabled' : firstName.status}
                                errorMessage={firstName.error}
                                onPersist={(event) => dispatch(setFirstNameValue(event.detail))}
                            />
                            <OmFormInputFieldWrapper
                                isSensitive
                                required
                                formId="beneficiaryDetails"
                                size="full"
                                align="left"
                                name="surname"
                                placeholder="Surname"
                                validationDelay={DEFAULT_VALIDATION_DELAY}
                                value={surname.displayValue}
                                state={isExistingMember ? 'disabled' : surname.status}
                                errorMessage={surname.error}
                                onPersist={(event) => dispatch(setSurnameValue(event.detail))}
                            />
                            <OmFormInputFieldWrapper
                                isSensitive
                                required
                                labelAlwaysShow
                                formId="beneficiaryDetails"
                                size="full"
                                align="left"
                                name="dateOfBirth"
                                mask="date"
                                placeholder="dd/mm/yyyy"
                                label="Date of birth"
                                validationDelay={DEFAULT_VALIDATION_DELAY}
                                value={dateOfBirth.value}
                                state={isExistingMember ? 'disabled' : dateOfBirth.status}
                                errorMessage={dateOfBirth.error}
                                onPersist={(event) => dispatch(setDateOfBirthValue(event.detail))}
                            />
                            <OmFormDropdownFieldWrapper
                                className="inline-field"
                                useNativeMobileDropdown
                                required
                                size="small"
                                formId="beneficiaryDetails"
                                placeholder="Relationship"
                                name="relationship"
                                align="left"
                                disabled={isExistingMember}
                                onOnSelect={(event) =>
                                    dispatch(setRelationshipValue(event.detail.id))
                                }
                                value={relationship.value}
                                state={relationship.status}
                                errorMessage={relationship.error}
                                options={
                                    relationships &&
                                    JSON.stringify(
                                        relationships.map((option) => ({
                                            value: option,
                                            label: option,
                                        }))
                                    )
                                }
                            />

                            <h6 className="no-bottom-margin">
                                {' '}
                                Please supply at least one of the following:{' '}
                            </h6>
                            <div className="mobile-field">
                                <OmMobileInputFieldWrapper
                                    onOnPersist={(event) => mobilePersistHandler(event)}
                                    formId="beneficiaryDetails"
                                    validationDelay={DEFAULT_VALIDATION_DELAY}
                                    state={mobileNumber.status}
                                    value={mobileNumber.displayValue}
                                    errorMessage={mobileNumber.error}
                                    hintText={
                                        mobileNumber.status === FORM_STATUS.VALID
                                            ? ''
                                            : 'Remember to add “0” in front of your mobile number. E.g. 082 123 4567'
                                    }
                                />
                            </div>

                            <OmFormInputFieldWrapper
                                isSensitive
                                required
                                formId="beneficiaryDetails"
                                size="full"
                                align="left"
                                name="email"
                                placeholder="Email"
                                validationDelay={DEFAULT_VALIDATION_DELAY}
                                value={email.displayValue}
                                state={email.status}
                                errorMessage={email.error}
                                onPersist={(event) => dispatch(setEmailValue(event.detail))}
                            />
                        </div>

                        <div className="action-buttons">
                            <OmButton
                                className="cancel-button"
                                type="text"
                                size="small"
                                text="CANCEL"
                                onClick={cancelAndCloseEditing}
                            />

                            <OmButton
                                className="save-button"
                                type="primary"
                                size="small"
                                text="SAVE BENEFICIARY"
                                onClick={saveAndCloseBeneficiary}
                            />
                        </div>
                    </div>
                </OmApplicationBeneficiaryDetails>
            </div>
        </OmSideDrawer>
    );
};

export default BeneficiariesDetailsSideDrawer;
