import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { navigate } from 'gatsby';

import {
    OmFormInputFieldWrapper,
    OmCheckBox,
    OmFormDropdownFieldWrapper,
    OmModalLoader,
    OmApplicationSummaryDetailsCard,
    OmEfpPaymentDetails,
    OmFormError,
} from '@om/component-library-react';

import ErrorResponseModal from './popups/ErrorResponseModal';
import SuccessModal from './popups/SuccessModal';
import ServerErrorResponseModal from './popups/ServerErrorResponseModal';
import {
    setBankNameValue,
    setAccountNumberValue,
    setAccountTypeValue,
    setDebitOrderDayValue,
    setAccountValidationReset,
    togglePaymentAuthorization,
    toggleIncludeAvs,
    toggleIncludeCdv,
    setAccountValidationStatus,
} from '@om/redux/actions/easiplusFuneralPlan/paymentDetails';
import { getFirstNames, getSurname } from '@om/redux/selectors/easiplusFuneralPlan/personalDetails';
import {
    getBankName,
    getAccountNumber,
    getAccountType,
    getDebitOrderDay,
    getPaymentAuthorizationValid,
    getPaymentAuthorizationInvalid,
    getPaymentDetailsCompleted,
    getPaymentAuthorization,
    getAccountValidationStatus,
    getDisplaySuccessModal
} from '@om/redux/selectors/easiplusFuneralPlan/paymentDetails';
import {
    getReferenceBanks,
    getReferenceAccountTypes,
    getReferenceDebitOrderDates,
} from '@om/redux/selectors/easiplusFuneralPlan/referenceData';
import { getTotalPremium } from '@om/redux/selectors/easiplusFuneralPlan/livesCovered';
import {
    PRODUCT_DETAIL_URL,
    ACCOUNT_VALIDATION_STATUS,
} from '@om/redux/actions/easiplusFuneralPlan/types';
import { DEFAULT_VALIDATION_DELAY } from '../../config';
import { navigateNext } from '@om/redux/actions/easiplusFuneralPlan/routing';

import {
    getDeclarationsValid,
    getDeclarationsInvalid,
    getDeclarationsError,
} from '@om/redux/selectors/easiplusFuneralPlan/confirmDetails';

import { getSkipConfirmationPage } from '@om/redux/selectors/easiplusFuneralPlan/confirmDetails';
import {
    toggleDeclarations,
    setDeclarationsDrawer,
    skipConfirmationPage
} from '@om/redux/actions/easiplusFuneralPlan/confirmDetails';

const PaymentDetailsPage = ({includeAvs, includeCdv}) => {
    const dispatch = useDispatch();
    const {
        paymentAuthorizationValid,
        paymentAuthorizationInvalid,
        name,
        surname,
        bankName,
        accountNumber,
        accountType,
        debitOrderDay,
        paymentAuthorization,

        referenceBanks,
        referenceAccountTypes,
        referenceDebitOrderDates,

        accountValidationStatus,

        paymentDetailsCompleted,

        declarationsChecked,
        declarationsInvalid,
        declarationErrorMessage,
        skipConfirmationPage,
        premium,
        displaySuccessModal
    } = useSelector(
        createStructuredSelector({
            paymentAuthorizationValid: getPaymentAuthorizationValid,
            paymentAuthorizationInvalid: getPaymentAuthorizationInvalid,

            name: getFirstNames,
            surname: getSurname,
            bankName: getBankName,
            accountNumber: getAccountNumber,
            accountType: getAccountType,
            debitOrderDay: getDebitOrderDay,
            paymentAuthorization: getPaymentAuthorization,

            referenceBanks: getReferenceBanks,
            referenceAccountTypes: getReferenceAccountTypes,
            referenceDebitOrderDates: getReferenceDebitOrderDates,
            accountValidationStatus: getAccountValidationStatus,

            paymentDetailsCompleted: getPaymentDetailsCompleted,

            declarationsChecked: getDeclarationsValid,
            declarationsInvalid: getDeclarationsInvalid,
            declarationErrorMessage: getDeclarationsError,

            skipConfirmationPage: getSkipConfirmationPage,
            premium: getTotalPremium,
            displaySuccessModal: getDisplaySuccessModal
        })
    );

    useEffect(() => {
        if (includeAvs) {
            dispatch(toggleIncludeAvs());
        }

        if (includeCdv) {
            dispatch(toggleIncludeCdv());
        }
    }, []);

    return (
        <OmEfpPaymentDetails>
            <h5 slot="heading" className="heading" id="heading-anchor">
                Now, we'd like to know <strong>your payment details</strong>
            </h5>

            { skipConfirmationPage &&
                <OmApplicationSummaryDetailsCard slot="summary-card">
                    <div slot="content">
                        <h6 className="no-top-margin no-bottom-margin total-heading">total monthly premium is:</h6>
                        <div className="wrap-text">
                            <h5 className="no-top-margin no-bottom-margin medium">R{premium} p/m</h5>
                            <h4 className="no-top-margin no-bottom-margin">Total monthly premium</h4>
                        </div>
                    </div>
                </OmApplicationSummaryDetailsCard>
            }

            { !skipConfirmationPage &&
                <p slot="subheading">We need the following information to setup your contract.</p>
            }
            <div slot="form">
                <section className="form-section">
                    <OmFormDropdownFieldWrapper
                        useNativeMobileDropdown
                        required
                        formId="paymentDetailsForm"
                        placeholder="Bank name"
                        name="bankName"
                        align="left"
                        onOnSelect={(event) => dispatch(setBankNameValue(event.detail.id))}
                        value={bankName.value}
                        state={bankName.status}
                        error-message={bankName.error}
                        options={
                            referenceBanks &&
                            JSON.stringify(
                                referenceBanks.map((option) => ({
                                    value: option.code,
                                    label: option.description,
                                }))
                            )
                        }
                    />
                    <OmFormInputFieldWrapper
                        isSensitive
                        required
                        id="accountHolderName"
                        size="full"
                        align="left"
                        placeholder="Account holder name"
                        form-id="paymentDetailsForm"
                        name="accountHolderName"
                        error-message={name.error}
                        state="disabled"
                        value={name.value + ' ' + surname.value}
                    />
                    <OmFormInputFieldWrapper
                        isSensitive
                        required
                        id="accountNumber"
                        size="full"
                        align="left"
                        mask="number"
                        placeholder="Account number"
                        form-id="paymentDetailsForm"
                        name="accountNumber"
                        keyboard-mode="numeric"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        value={accountNumber.value}
                        error-message={accountNumber.error}
                        state={accountNumber.status}
                        onPersist={(event) => {
                            if (event.detail !== accountNumber.value) {
                                dispatch(setAccountNumberValue(event.detail));
                            }
                        }}
                    />
                    <OmFormDropdownFieldWrapper
                        useNativeMobileDropdown
                        required
                        formId="paymentDetailsForm"
                        placeholder="Account type"
                        name="accountType"
                        align="left"
                        onOnSelect={(event) => dispatch(setAccountTypeValue(event.detail.id))}
                        value={accountType.value}
                        error-message={accountType.error}
                        state={accountType.status}
                        options={
                            referenceAccountTypes &&
                            JSON.stringify(
                                referenceAccountTypes.map((option) => ({
                                    value: option.code,
                                    label: option.description,
                                }))
                            )
                        }
                    />
                    <OmFormDropdownFieldWrapper
                        useNativeMobileDropdown
                        required
                        formId="paymentDetailsForm"
                        placeholder="Select a day for your debit order"
                        name="debitOrderDay"
                        align="left"
                        onOnSelect={(event) => dispatch(setDebitOrderDayValue(event.detail.value))}
                        value={debitOrderDay.value}
                        error-message={debitOrderDay.error}
                        state={debitOrderDay.status}
                        options={
                            referenceDebitOrderDates &&
                            JSON.stringify(
                                referenceDebitOrderDates.map((option) => ({
                                    value: option.code,
                                    label: option.description,
                                }))
                            )
                        }
                    />
                    <div className="check-box-container" id="paymentAuthorization">
                        <OmCheckBox
                            disabled={!paymentDetailsCompleted}
                            text="I hereby confirm that the above-provided banking details are mine. I understand that my application will be declined if the provided banking details are not mine."
                            checked={paymentAuthorizationValid}
                            value={paymentAuthorization.value}
                            onOnCheckBoxChanged={() => {
                                dispatch(togglePaymentAuthorization());
                            }}
                        />
                        <OmFormError
                            message={
                                paymentAuthorizationInvalid
                                    ? 'Authorization required to continue.'
                                    : ''
                            }
                        ></OmFormError>
                    </div>
                    {
                        skipConfirmationPage &&
                        <div className="check-box-container">
                            <OmCheckBox
                                checked={declarationsChecked}
                                onOnCheckBoxChanged={() => dispatch(toggleDeclarations())}
                            >
                                <p slot="text-slot">
                                    I accept the&nbsp;
                                    <button onClick={() => dispatch(setDeclarationsDrawer(true))}>
                                        declarations.
                                    </button>
                                </p>
                            </OmCheckBox>
                            <OmFormError message={declarationErrorMessage}></OmFormError>
                        </div>
                    }
                </section>
            </div>

            <OmModalLoader
                slot="loading-modal"
                loaderText="Verifying your bank account details"
                verticalAlignMiddle="true"
                open={accountValidationStatus === ACCOUNT_VALIDATION_STATUS.PENDING}
            />

            <ErrorResponseModal
                slot="error-modal"
                open={accountValidationStatus === ACCOUNT_VALIDATION_STATUS.FAILED}
                buttonHandler={() => dispatch(setAccountValidationReset())}
            />

            <SuccessModal
                slot="good-to-go-modal"
                open={displaySuccessModal}
                buttonHandler={() =>
                    skipConfirmationPage
                        ? dispatch(setAccountValidationStatus(ACCOUNT_VALIDATION_STATUS.IDLE))
                        : dispatch(navigateNext())}
            />

            <ServerErrorResponseModal
                slot="server-error-modal"
                open={accountValidationStatus === ACCOUNT_VALIDATION_STATUS.ERROR}
                buttonHandler={() => {
                    dispatch(setAccountValidationReset());
                    navigate(PRODUCT_DETAIL_URL);
                }}
            />
        </OmEfpPaymentDetails>
    );
};

export default PaymentDetailsPage;
