import React from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useDispatch } from 'react-redux';
import {
    setShowMustHaveBeneficiaryValue,
    addNewBeneficiary,
    toggleEditDrawer,
} from '@om/redux/actions/easiplusFuneralPlan/beneficiaries';
import { getShowMustHaveBeneficiary, getAddedMaxBeneficiaries } from '@om/redux/selectors/easiplusFuneralPlan/beneficiaries';
import OmButton from '../../../components/forms/OmButton';
import {
    OmApplicationPopupModal
} from '@om/component-library-react';

const MustHaveBeneficiaryModal = () => {
    const dispatch = useDispatch();

    const {
        showMustHaveBeneficiary,
        addedMaxBeneficiaries
    } = useSelector(
        createStructuredSelector({
            showMustHaveBeneficiary: getShowMustHaveBeneficiary,
            addedMaxBeneficiaries: getAddedMaxBeneficiaries,
        })
    );

    const modalClosedHandler = () => {
        dispatch(setShowMustHaveBeneficiaryValue(false));
    };



    const addNewBeneficiaryHandler = () => {
        if(addedMaxBeneficiaries){
            return;
        }
        dispatch(setShowMustHaveBeneficiaryValue(false));
        dispatch(addNewBeneficiary());
        dispatch(toggleEditDrawer())
    }

    return (
        <OmApplicationPopupModal
            onModalClosed={modalClosedHandler}
            name="mustHaveBeneficiary"
            open={showMustHaveBeneficiary}
            dismissible>
            <div slot="content">
                <p>Due to you being the only direct family member covered by this policy, you need to add a beneficiary.</p>
            </div>
            <div slot="button">
                <OmButton
                    type="primary"
                    size="small"
                    text="ADD BENEFICIARY"
                    onClick={addNewBeneficiaryHandler}
                />
            </div>
        </OmApplicationPopupModal>
    );
};

export default MustHaveBeneficiaryModal;