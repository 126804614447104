import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {graphql} from 'gatsby';
import {getReferenceNumber} from '@om/redux/selectors/easiplusFuneralPlan/thankYou';
import {getFirstNames} from '@om/redux/selectors/easiplusFuneralPlan/thankYou';
import {setGenericProductValue} from '@om/redux/actions/easiplusFuneralPlan/callMeBack';
import {resetQuote} from '@om/redux/actions/easiplusFuneralPlan/quote';
import {resetReplacementPolicy} from '@om/redux/actions/easiplusFuneralPlan/replacementPolicy';
import {resetPersonalDetails} from '@om/redux/actions/easiplusFuneralPlan/personalDetails';
import {resetLivesCovered} from '@om/redux/actions/easiplusFuneralPlan/livesCovered';
import {resetBeneficiaries} from '@om/redux/actions/easiplusFuneralPlan/beneficiaries';
import {resetBeneficiariesSplit} from '@om/redux/actions/easiplusFuneralPlan/beneficiariesSplit';
import {resetPaymentDetails} from '@om/redux/actions/easiplusFuneralPlan/paymentDetails';
import {resetConfirmDetails} from '@om/redux/actions/easiplusFuneralPlan/confirmDetails';
import {resetCallMeBack} from '@om/redux/actions/easiplusFuneralPlan/callMeBack';
import {resetScroll} from '@om/redux/actions/easiplusFuneralPlan/scrolling';
import {resetRouting} from '@om/redux/actions/easiplusFuneralPlan/routing';
import {
    OmApplicationThankYouPage,
    OmApplicationThankYou
} from '@om/component-library-react';
import FourColumnIllustration from '../../../components/FourColumnIllustration';
import ThreeColumnIllustration from '../../../components/ThreeColumnIllustration';

const ThankYouPage = ({
    thankYouEntry,
    threeColumnLayout,
    fourColumnLayout,
    callMeBack
}) => {
    const dispatch = useDispatch();
    const {
        applicationReferenceNumber,
        firstName,
    } = useSelector(
        createStructuredSelector({
            applicationReferenceNumber: getReferenceNumber,
            firstName: getFirstNames
        })
    );

    // set call me back
    useEffect(() => {
        // reset the application when landing on thank you page
        dispatch(resetQuote());
        dispatch(resetReplacementPolicy());
        dispatch(resetPersonalDetails());
        dispatch(resetLivesCovered());
        dispatch(resetBeneficiaries());
        dispatch(resetBeneficiariesSplit());
        dispatch(resetPaymentDetails());
        dispatch(resetConfirmDetails());
        dispatch(resetCallMeBack());
        dispatch(resetScroll());
        dispatch(resetRouting());

        if (callMeBack && callMeBack[0] && callMeBack[0].call_me_back_product) {
            dispatch(setGenericProductValue(callMeBack[0].call_me_back_product));
        }
    }, [callMeBack, dispatch]);

    if (!thankYouEntry) {
        return '';
    }

    const {
        close_button_link,
        success_image_link,
        caption,
        contact_caption,
        button
    } = thankYouEntry[0];

    return (
        <OmApplicationThankYouPage>
            <OmApplicationThankYou
                slot="thank-you"
                closeButtonLink={close_button_link && close_button_link.href
                    ? close_button_link.href
                    : ''
                }
                imageLink={success_image_link && success_image_link.href
                    ? success_image_link.href
                    : ''
                }
                personName={firstName}
                thankYouCaption={caption}
                referenceNumber={applicationReferenceNumber}
                contactCaption={contact_caption}
                buttonName={button ? button.title : ''}
                buttonLink={button ? button.href : ''}
            />

            {/* False so that 4 column can be swapped for 3 column if needed */}
            { false && 
                <ThreeColumnIllustration
                    slot="three-col"
                    threeColumnIllustrationContent={threeColumnLayout && threeColumnLayout[0]}
                    buttonType="primary"
                />
            }

            <FourColumnIllustration 
                slot="three-col"
                fourColumnLayout={fourColumnLayout}
            />
        </OmApplicationThankYouPage>
    );
};

export const pageQuery = graphql`
    fragment ThankYou on Contentstack_thank_you {
        close_button_link {
            ...Link
        }
        success_image_link {
            ...Link
        }
        caption
        contact_caption
        button {
            title
            ...Link
        }

    }
`;

export default ThankYouPage;
