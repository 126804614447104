import { createSelector } from "reselect";
import { getEasiplusFuneralPlan } from "./index";

export const getSessionCamVariables = createSelector(getEasiplusFuneralPlan, efp => efp.sessionCamVariables);

export const getRequestId = createSelector(
    getSessionCamVariables,
    sessionCamVariables => sessionCamVariables ? sessionCamVariables.requestId : ''
);

