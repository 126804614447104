import React from 'react';
import OmButton from '../../../../components/forms/OmButton';
import { OmApplicationPopupModal } from '@om/component-library-react';

const MaxMemberModal = ({ open, confirmHandler }) => {
    return (
        <OmApplicationPopupModal
            onModalClosed={() => confirmHandler()}
            name="LivesCoveredMaxMembersModal"
            dismissible
            exitable
            open={open}>
            <div slot="content">
                <p>Unfortunately, you are only allowed to add cover for a maximum of 7 children</p>
            </div>
            <div slot="button">
                <OmButton
                    type="primary"
                    size="small"
                    text="Okay, thanks"
                    onClick={() => confirmHandler()}
                />
            </div>
        </OmApplicationPopupModal>
    );
};

export default MaxMemberModal;
