import { createSelector } from "reselect";
import { getEasiplusFuneralPlan } from "./index";

export const getScrolling = createSelector(
    getEasiplusFuneralPlan,
    easiplusFuneralPlan => easiplusFuneralPlan.scrolling
);

export const getScrollToClass = createSelector(
    getScrolling,
    scrolling => scrolling.scrollToClass,
)

export const getScrollToId = createSelector(
    getScrolling,
    scrolling => scrolling.scrollToId,
)