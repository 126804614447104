import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    OmFormInputFieldWrapper,
    OmMobileInputFieldWrapper,
    OmRadioChipButtonGroup,
    OmFormDropdownFieldWrapper,
    OmEfpPersonalDetails,
} from '@om/component-library-react';
import { FORM_STATUS } from '@om/redux/reducers/status';

import {
    setCityValue,
    setComplexValue,
    setCountryValue,
    setEmailValue,
    setIdValue,
    setPoBoxValue,
    setPoCountryValue,
    setPoPostalCodeValue,
    setPoPostOfficeValue,
    setPostalAddressMethodValue,
    setPostalAddressSameValue,
    setPostalCityValue,
    setPostalCodeValue,
    setPostalComplexValue,
    setPostalCountryValue,
    setPostalPostalCodeValue,
    setPostalStreetAddressValue,
    setPostalSuburbValue,
    setPrivateBagValue,
    setPrivateCountryValue,
    setPrivatePostalCodeValue,
    setPrivatePostOfficeValue,
    setStreetAddressValue,
    setSuburbValue,
    setTitleValue,
    setFirstNamesValue,
    setSurnameValue,
    setMobileValue,
} from '@om/redux/actions/easiplusFuneralPlan/personalDetails';
import { setFirstNamesValue as thankYouSetFirstNamesValue } from '@om/redux/actions/easiplusFuneralPlan/thankYou';
import {
    getCity,
    getComplex,
    getCountry,
    getEmail,
    getFirstNames,
    getId,
    getMobile,
    getPoBox,
    getPoCountry,
    getPoPostalCode,
    getPoPostOffice,
    getPostalAddressMethod,
    getPostalAddressSame,
    getPostalCity,
    getPostalCode,
    getPostalComplex,
    getPostalCountry,
    getPostalPostalCode,
    getPostalStreetAddress,
    getPostalSuburb,
    getPrivateBag,
    getPrivateCountry,
    getPrivatePostalCode,
    getPrivatePostOffice,
    getStreetAddress,
    getSuburb,
    getSurname,
    getTitle,
    getPreferredPostalMethod,
    getIsPostalAddressMethodStreetAddress,
    getIsPostalAddressMethodPoBox,
    getIsPostalAddressMethodPrivateBag,
    getIsPostalAddressSameAsResidential,
} from '@om/redux/selectors/easiplusFuneralPlan/personalDetails';
import {
    getReferenceTitles,
    getReferencePostalMethods,
    getReferenceNationalities,
} from '@om/redux/selectors/easiplusFuneralPlan/referenceData';
import { DEFAULT_VALIDATION_DELAY } from '../../config';

const PersonalDetailsPage = () => {
    const formFieldProperties = {
        required: true,
        size: 'full',
        formId: 'personalDetailsForm',
        align: 'left',
        validationDelay: DEFAULT_VALIDATION_DELAY,
    };

    const dropdownFieldProperties = {
        useNativeMobileDropdown: true,
        required: true,
        formId: 'personalDetailsForm',
        align: 'left',
    };

    const dispatch = useDispatch();
    const {
        city,
        complex,
        country,
        email,
        firstNames,
        id,
        mobile,
        poBox,
        poCountry,
        poPostalCode,
        poPostOffice,
        postalAddressMethod,
        postalAddressSame,
        postalCity,
        postalCode,
        postalComplex,
        postalCountry,
        postalPostalCode,
        postalStreetAddress,
        postalSuburb,
        privateBag,
        privateCountry,
        privatePostalCode,
        privatePostOffice,
        streetAddress,
        suburb,
        surname,
        title,
        preferredPostalMethod,
        referenceTitles,
        referencePostalMethods,
        referenceNationalities,
        isPostalAddressMethodStreetAddress,
        isPostalAddressMethodPoBox,
        isPostalAddressMethodPrivateBag,
        isPostalAddressSameAsResidential,
    } = useSelector(
        createStructuredSelector({
            city: getCity,
            complex: getComplex,
            country: getCountry,
            email: getEmail,
            firstNames: getFirstNames,
            id: getId,
            mobile: getMobile,
            poBox: getPoBox,
            poCountry: getPoCountry,
            poPostalCode: getPoPostalCode,
            poPostOffice: getPoPostOffice,
            postalAddressMethod: getPostalAddressMethod,
            postalAddressSame: getPostalAddressSame,
            postalCity: getPostalCity,
            postalCode: getPostalCode,
            postalComplex: getPostalComplex,
            postalCountry: getPostalCountry,
            postalPostalCode: getPostalPostalCode,
            postalStreetAddress: getPostalStreetAddress,
            postalSuburb: getPostalSuburb,
            privateBag: getPrivateBag,
            privateCountry: getPrivateCountry,
            privatePostalCode: getPrivatePostalCode,
            privatePostOffice: getPrivatePostOffice,
            streetAddress: getStreetAddress,
            suburb: getSuburb,
            surname: getSurname,
            title: getTitle,
            preferredPostalMethod: getPreferredPostalMethod,
            referenceTitles: getReferenceTitles,
            referencePostalMethods: getReferencePostalMethods,
            referenceNationalities: getReferenceNationalities,
            isPostalAddressMethodStreetAddress: getIsPostalAddressMethodStreetAddress,
            isPostalAddressMethodPoBox: getIsPostalAddressMethodPoBox,
            isPostalAddressMethodPrivateBag: getIsPostalAddressMethodPrivateBag,
            isPostalAddressSameAsResidential: getIsPostalAddressSameAsResidential,
        })
    );

    const updatePostalAddressMethod = (eventDetail) => {
        if (eventDetail.id !== postalAddressMethod.value)
            dispatch(setPostalAddressMethodValue(eventDetail.id));
    };

    return (
        <OmEfpPersonalDetails>
            <h5 slot="heading" className="heading">
                Let’s get to know you a little better
            </h5>

            <div slot="form">
                <section className="form-section">
                    <h6 className="no-bottom-margin">
                        <strong>Personal details</strong>
                    </h6>

                    <OmFormDropdownFieldWrapper
                        {...dropdownFieldProperties}
                        id="title"
                        name="title"
                        placeholder="Title"
                        value={title.value}
                        errorMessagePopupAlignment="left"
                        errorMessage={title.error}
                        onOnSelect={(event) => dispatch(setTitleValue(event.detail.id))}
                        options={
                            referenceTitles &&
                            JSON.stringify(
                                referenceTitles.map((referenceTitle) => ({
                                    value: referenceTitle.code,
                                    label: referenceTitle.description,
                                }))
                            )
                        }
                    />
                    <OmFormInputFieldWrapper
                        {...formFieldProperties}
                        isSensitive
                        id="firstNames"
                        name="name"
                        placeholder="First name(s)"
                        value={firstNames.displayValue}
                        state={firstNames.status}
                        errorMessage={firstNames.error}
                        onPersist={(event) => {
                            dispatch(setFirstNamesValue(event.detail));
                            dispatch(thankYouSetFirstNamesValue(event.detail));
                        }}
                    />
                    <OmFormInputFieldWrapper
                        {...formFieldProperties}
                        isSensitive
                        id="surname"
                        name="surname"
                        placeholder="Surname"
                        value={surname.displayValue}
                        state={surname.status}
                        errorMessage={surname.error}
                        onPersist={(event) => dispatch(setSurnameValue(event.detail))}
                    />
                    <OmFormInputFieldWrapper
                        {...formFieldProperties}
                        isSensitive
                        id="id"
                        placeholder="South African ID number"
                        name="id"
                        keyboardMode="numeric"
                        value={id.displayValue}
                        state={id.status}
                        errorMessage={id.error}
                        onPersist={(event) => dispatch(setIdValue(event.detail))}
                    />

                    <h6 className="no-bottom-margin">
                        <strong>Contact details</strong>
                    </h6>

                    <div className="mobile-field">
                        <OmMobileInputFieldWrapper
                            id="mobileNumber"
                            formId="personalDetailsForm"
                            placeholderText="Contact Number"
                            validationDelay={DEFAULT_VALIDATION_DELAY}
                            value={mobile.displayValue}
                            state={mobile.status}
                            errorMessage={mobile.error}
                            onPersist={(event) => dispatch(setMobileValue(event.detail))}
                            hintText={
                                mobile.status === FORM_STATUS.VALID
                                    ? ''
                                    : 'Remember to add “0” in front of your mobile number. E.g. 082 123 4567'
                            }
                        />
                    </div>
                    <OmFormInputFieldWrapper
                        {...formFieldProperties}
                        isSensitive
                        id="email"
                        placeholder="Email"
                        name="email"
                        value={email.displayValue}
                        state={email.status}
                        errorMessage={email.error}
                        onPersist={(event) => dispatch(setEmailValue(event.detail))}
                    />

                    <h6 className="no-bottom-margin">
                        <strong>Residential address details</strong>
                    </h6>
                    <p>
                        PO BOX or Private Bag addresses will not be accepted as a residential address. This will result in your application being declined.
                    </p>
                    <OmFormInputFieldWrapper
                        {...formFieldProperties}
                        isSensitive
                        id="complex"
                        placeholder="Complex/building"
                        name="complex"
                        value={complex.value}
                        state={complex.status}
                        errorMessage={complex.error}
                        onPersist={(event) => dispatch(setComplexValue(event.detail))}
                    />
                    <OmFormInputFieldWrapper
                        {...formFieldProperties}
                        isSensitive
                        id="streetAddress"
                        placeholder="Street address"
                        name="streetAddress"
                        value={streetAddress.value}
                        state={streetAddress.status}
                        errorMessage={streetAddress.error}
                        onPersist={(event) => dispatch(setStreetAddressValue(event.detail))}
                        hintText="Only a street address is allowed."
                    />
                    <OmFormInputFieldWrapper
                        {...formFieldProperties}
                        isSensitive
                        id="suburb"
                        placeholder="Suburb"
                        name="suburb"
                        value={suburb.value}
                        state={suburb.status}
                        errorMessage={suburb.error}
                        onPersist={(event) => dispatch(setSuburbValue(event.detail))}
                    />
                    <OmFormInputFieldWrapper
                        {...formFieldProperties}
                        id="city"
                        placeholder="City"
                        name="city"
                        value={city.value}
                        state={city.status}
                        errorMessage={city.error}
                        onPersist={(event) => dispatch(setCityValue(event.detail))}
                    />
                    <OmFormInputFieldWrapper
                        {...formFieldProperties}
                        isSensitive
                        id="postalCode"
                        placeholder="Postal code"
                        name="postalCode"
                        value={postalCode.displayValue}
                        state={postalCode.status}
                        errorMessage={postalCode.error}
                        onPersist={(event) => dispatch(setPostalCodeValue(event.detail))}
                    />
                    <OmFormDropdownFieldWrapper
                        {...dropdownFieldProperties}
                        size="full"
                        id="country"
                        name="country"
                        placeholder="Country"
                        disabled="true"
                        filterable="true"
                        errorMessagePopupAlignment="left"
                        value={country.value}
                        onOnSelect={(event) => dispatch(setCountryValue(event.detail.value))}
                        options={
                            referenceNationalities &&
                            JSON.stringify(
                                referenceNationalities.map((referenceNationality) => ({
                                    value: referenceNationality.code,
                                    label: referenceNationality.description,
                                }))
                            )
                        }
                    />

                    <h6>
                        <strong>Is your postal address the same?</strong>
                    </h6>

                    <OmRadioChipButtonGroup
                        id="postalAddressSame"
                        value={postalAddressSame.value ? '1' : '2'}
                        groupName="postalAddressSameGroup"
                        onOnSelection={(event) =>
                            dispatch(setPostalAddressSameValue(event.detail === '1'))
                        }
                    >
                        <ul slot="list-items">
                            <li value="1">Yes</li>
                            <li value="2">No</li>
                        </ul>
                    </OmRadioChipButtonGroup>

                    {!isPostalAddressSameAsResidential && (
                        <div>
                            <p className="form-text">Select your preferred postal method</p>
                            <OmFormDropdownFieldWrapper
                                {...dropdownFieldProperties}
                                size="full"
                                id="postalAddressMethod"
                                name="postalAddressMethod"
                                value={postalAddressMethod.value}
                                state={postalAddressMethod.status}
                                errorMessage={postalAddressMethod.error}
                                errorMessagePopupAlignment="left"
                                onOnSelect={(event) => updatePostalAddressMethod(event.detail)}
                                options={
                                    referencePostalMethods &&
                                    JSON.stringify(
                                        referencePostalMethods.map((referencePostalMethod) => ({
                                            value: referencePostalMethod.code,
                                            label: referencePostalMethod.description,
                                        }))
                                    )
                                }
                            />

                            {preferredPostalMethod && (
                                <p className="form-text">Please complete the following:</p>
                            )}

                            {isPostalAddressMethodStreetAddress && (
                                <div>
                                    <OmFormInputFieldWrapper
                                        {...formFieldProperties}
                                        isSensitive
                                        id="postalComplex"
                                        name="postalComplex"
                                        placeholder="Complex/building"
                                        value={postalComplex.value}
                                        state={postalComplex.status}
                                        errorMessage={postalComplex.error}
                                        onPersist={(event) =>
                                            dispatch(setPostalComplexValue(event.detail))
                                        }
                                    />
                                    <OmFormInputFieldWrapper
                                        {...formFieldProperties}
                                        isSensitive
                                        id="postalStreetAddress"
                                        name="postalStreetAddress"
                                        placeholder="Street address"
                                        value={postalStreetAddress.value}
                                        state={postalStreetAddress.status}
                                        errorMessage={postalStreetAddress.error}
                                        onPersist={(event) =>
                                            dispatch(setPostalStreetAddressValue(event.detail))
                                        }
                                        hintText="Only a street address is allowed."
                                    />
                                    <OmFormInputFieldWrapper
                                        {...formFieldProperties}
                                        isSensitive
                                        id="postalSuburb"
                                        name="postalSuburb"
                                        placeholder="Suburb"
                                        value={postalSuburb.value}
                                        state={postalSuburb.status}
                                        errorMessage={postalSuburb.error}
                                        onPersist={(event) =>
                                            dispatch(setPostalSuburbValue(event.detail))
                                        }
                                    />
                                    <OmFormInputFieldWrapper
                                        {...formFieldProperties}
                                        id="postalCity"
                                        name="postalCity"
                                        placeholder="City"
                                        value={postalCity.value}
                                        state={postalCity.status}
                                        errorMessage={postalCity.error}
                                        onPersist={(event) =>
                                            dispatch(setPostalCityValue(event.detail))
                                        }
                                    />
                                    <OmFormInputFieldWrapper
                                        {...formFieldProperties}
                                        isSensitive
                                        id="postalPostalCode"
                                        name="postalPostalCode"
                                        placeholder="Postal code"
                                        value={postalPostalCode.displayValue}
                                        state={postalPostalCode.status}
                                        errorMessage={postalPostalCode.error}
                                        onPersist={(event) =>
                                            dispatch(setPostalPostalCodeValue(event.detail))
                                        }
                                    />

                                    <OmFormDropdownFieldWrapper
                                        {...dropdownFieldProperties}
                                        size="full"
                                        id="postalCountry"
                                        name="postalCountry"
                                        placeholder="Country"
                                        disabled="true"
                                        filterable="true"
                                        value={postalCountry.value}
                                        errorMessagePopupAlignment="left"
                                        errorMessage={postalCountry.error}
                                        onOnSelect={(event) =>
                                            dispatch(setPostalCountryValue(event.detail.value))
                                        }
                                        options={
                                            referenceNationalities &&
                                            JSON.stringify(
                                                referenceNationalities.map(
                                                    (referenceNationality) => ({
                                                        value: referenceNationality.code,
                                                        label: referenceNationality.description,
                                                    })
                                                )
                                            )
                                        }
                                    />
                                </div>
                            )}

                            {isPostalAddressMethodPoBox && (
                                <div>
                                    <OmFormInputFieldWrapper
                                        {...formFieldProperties}
                                        isSensitive
                                        id="poBox"
                                        name="poBox"
                                        placeholder="PO box"
                                        value={poBox.displayValue}
                                        state={poBox.status}
                                        errorMessage={poBox.error}
                                        onPersist={(event) => dispatch(setPoBoxValue(event.detail))}
                                    />
                                    <OmFormInputFieldWrapper
                                        {...formFieldProperties}
                                        isSensitive
                                        id="poPostOffice"
                                        name="poPostOffice"
                                        placeholder="Post office"
                                        value={poPostOffice.value}
                                        state={poPostOffice.status}
                                        errorMessage={poPostOffice.error}
                                        onPersist={(event) =>
                                            dispatch(setPoPostOfficeValue(event.detail))
                                        }
                                    />
                                    <OmFormInputFieldWrapper
                                        {...formFieldProperties}
                                        isSensitive
                                        id="poPostalCode"
                                        name="poPostalCode"
                                        placeholder="Postal code"
                                        value={poPostalCode.displayValue}
                                        state={poPostalCode.status}
                                        errorMessage={poPostalCode.error}
                                        onPersist={(event) =>
                                            dispatch(setPoPostalCodeValue(event.detail))
                                        }
                                    />

                                    <OmFormDropdownFieldWrapper
                                        {...dropdownFieldProperties}
                                        size="full"
                                        id="poCountry"
                                        name="poCountry"
                                        placeholder="Country"
                                        filterable="true"
                                        disabled="true"
                                        value={poCountry.value}
                                        errorMessage={poCountry.error}
                                        errorMessagePopupAlignment="left"
                                        onOnSelect={(event) =>
                                            dispatch(setPoCountryValue(event.detail.value))
                                        }
                                        options={
                                            referenceNationalities &&
                                            JSON.stringify(
                                                referenceNationalities.map(
                                                    (referenceNationality) => ({
                                                        value: referenceNationality.code,
                                                        label: referenceNationality.description,
                                                    })
                                                )
                                            )
                                        }
                                    />
                                </div>
                            )}

                            {isPostalAddressMethodPrivateBag && (
                                <div>
                                    <OmFormInputFieldWrapper
                                        {...formFieldProperties}
                                        isSensitive
                                        id="privateBag"
                                        name="privateBag"
                                        placeholder="Private bag number"
                                        value={privateBag.displayValue}
                                        state={privateBag.status}
                                        errorMessage={privateBag.error}
                                        onPersist={(event) =>
                                            dispatch(setPrivateBagValue(event.detail))
                                        }
                                    />
                                    <OmFormInputFieldWrapper
                                        {...formFieldProperties}
                                        isSensitive
                                        id="privatePostOffice"
                                        name="privatePostOffice"
                                        placeholder="Post office"
                                        value={privatePostOffice.value}
                                        state={privatePostOffice.status}
                                        errorMessage={privatePostOffice.error}
                                        onPersist={(event) =>
                                            dispatch(setPrivatePostOfficeValue(event.detail))
                                        }
                                    />
                                    <OmFormInputFieldWrapper
                                        {...formFieldProperties}
                                        isSensitive
                                        id="privatePostalCode"
                                        name="privatePostalCode"
                                        placeholder="Postal code"
                                        value={privatePostalCode.displayValue}
                                        state={privatePostalCode.status}
                                        errorMessage={privatePostalCode.error}
                                        onPersist={(event) =>
                                            dispatch(setPrivatePostalCodeValue(event.detail))
                                        }
                                    />

                                    <OmFormDropdownFieldWrapper
                                        {...dropdownFieldProperties}
                                        size="full"
                                        id="privateCountry"
                                        name="privateCountry"
                                        placeholder="Country"
                                        filterable="true"
                                        disabled="true"
                                        value={privateCountry.value}
                                        errorMessagePopupAlignment="left"
                                        errorMessage={privateCountry.error}
                                        onOnSelect={(event) =>
                                            dispatch(setPrivateCountryValue(event.detail.value))
                                        }
                                        options={
                                            referenceNationalities &&
                                            JSON.stringify(
                                                referenceNationalities.map(
                                                    (referenceNationality) => ({
                                                        value: referenceNationality.code,
                                                        label: referenceNationality.description,
                                                    })
                                                )
                                            )
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </section>
            </div>
        </OmEfpPersonalDetails>
    );
};

export default PersonalDetailsPage;
