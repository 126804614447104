import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {useLocation} from '@reach/router'
import {navigate} from 'gatsby';
import OmButton from '../../components/forms/OmButton';
import {PAGE_INDICES, PAGES, PRODUCT_DETAIL_URL} from '@om/redux/actions/easiplusFuneralPlan/types';
import {
    navigateNext,
    navigatePrevious,
    setNavigationDone,
} from '@om/redux/actions/easiplusFuneralPlan/routing';
import {
    getActivePageIndex,
    getContinueButtonText,
    getDoEfpNavigation
} from '@om/redux/selectors/easiplusFuneralPlan/routing';
import {getReplacementPolicyDisabled} from '@om/redux/selectors/easiplusFuneralPlan/replacementPolicy'
import {
    OmApplicationWizardFooter
} from '@om/component-library-react';
import {resetReplacementPolicy} from '@om/redux/actions/easiplusFuneralPlan/replacementPolicy';

const ApplicationWizardFooter = () => {
    const dispatch = useDispatch();

    const {
        activePageIndex,
        replacementPolicyDisabled,
        continueButtonText,
        doEfpNavigation,
    } = useSelector(
        createStructuredSelector({
            activePageIndex: getActivePageIndex,
            replacementPolicyDisabled: getReplacementPolicyDisabled,
            continueButtonText: getContinueButtonText,
            doEfpNavigation: getDoEfpNavigation,
        })
    );

    const location = useLocation();
    useEffect(() => {
        // if (doExitCvsNavigation) {
        //     navigate(parentAppUrl);
        //     return;
        // }

        if (!doEfpNavigation) {
            return;
        }

        const currentPageIndex = PAGES.map(page =>  page.URL).indexOf(location.pathname);
        if (currentPageIndex === activePageIndex) {
            dispatch(setNavigationDone());
            return;
        }

        navigate(PAGES[activePageIndex].URL);
    }, [location, activePageIndex, dispatch, doEfpNavigation]);

    const navigatePreviousHandler = () => {
        if (
            activePageIndex === PAGE_INDICES.REPLACEMENT_POLICY ||
            activePageIndex === PAGE_INDICES.PERSONAL_DETAILS
        ) {
            dispatch(resetReplacementPolicy());
            navigate(PRODUCT_DETAIL_URL);
        } else {
            dispatch(navigatePrevious());
        }
    };

    // TODO: Implement page types for page index and remove magic number
    return (activePageIndex !== 7 &&
        <OmApplicationWizardFooter
            index={ activePageIndex }
            totalCount={ PAGES.length }
        >
            <OmButton
                slot="left"
                type="text"
                size="small"
                text="Previous"
                disabled={ false }
                onClick={ navigatePreviousHandler }
            />

            <OmButton
                slot="right"
                type="primary"
                size="small"
                text={ continueButtonText }
                disabled={ activePageIndex === PAGE_INDICES.REPLACEMENT_POLICY }
                onClick={ () => dispatch(navigateNext()) }
            />
        </OmApplicationWizardFooter>
    );
};

export default ApplicationWizardFooter;
