import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    addNewBeneficiary,
    editBeneficiary,
    toggleEditDrawer,
    setConfirmRemoveBenefiaryId
} from '@om/redux/actions/easiplusFuneralPlan/beneficiaries';
import {
    getBeneficiaryList,
    getNBeneficiaries,
    getAddedMaxBeneficiaries
} from '@om/redux/selectors/easiplusFuneralPlan/beneficiaries';

import {
    OmApplicationButton,
    OmApplicationBeneficiaryList,
    OmApplicationBeneficiaryDetailsCard,
    OmEfpBeneficiaries,
} from '@om/component-library-react';

import BeneficiaryDetailsSideDrawer from './BeneficiaryDetailsSideDrawer';
import RemoveBeneficiaryModal from './RemoveBeneficiaryModal';
import MaxBeneficiariesAddedModal from './MaxBeneficiariesAddedModal';
import MustHaveBeneficiaryModal from './MustHaveBeneficiaryModal';


const ApplicationBeneficiaryDetailsCard = ({
    number,
    firstName,
    surname,
    editHandler,
    removeHandler
}) => {
    return (
        <OmApplicationBeneficiaryDetailsCard
            onEditBeneficiary={() => editHandler()}
            onPopupModal={() => removeHandler()}
            number={number}
            name={firstName}
            surname={surname}
        />
    );
};

const BeneficiariesPage = () => {
    const dispatch = useDispatch();

    const {
        beneficiaryList,
        numberOfBeneficiaries,
        addedMaxBeneficiaries
    } = useSelector(
        createStructuredSelector({
            beneficiaryList: getBeneficiaryList,
            numberOfBeneficiaries: getNBeneficiaries,
            addedMaxBeneficiaries: getAddedMaxBeneficiaries
        })
    );
    const editHandler = (id) => {
        dispatch(editBeneficiary(id));
        dispatch(toggleEditDrawer());
    };

    const removeHandler = (id) => {
        dispatch(setConfirmRemoveBenefiaryId(id));
    };

    const applicationButtonClickHandler = () => {
        if (addedMaxBeneficiaries){
            return;
        }

        dispatch(addNewBeneficiary());
        dispatch(toggleEditDrawer());
    };

    return (
        <OmEfpBeneficiaries>
            <BeneficiaryDetailsSideDrawer />
            <MaxBeneficiariesAddedModal />
            <RemoveBeneficiaryModal />
            <MustHaveBeneficiaryModal />

            {!numberOfBeneficiaries ?
                <div>
                    <h5 className="no-top-margin">
                        Why you should <span className="medium"> add a beneficiary </span>
                    </h5>

                    <ul className="trustees">
                        <li>
                            As the policyholder, you will receive the payouts should anything happen to the people you've covered
                        </li>
                        <li>
                            You can nominate someone to receive the payout in the event of your death.
                        </li>
                    </ul>

                    <OmApplicationButton
                        onClick={applicationButtonClickHandler}
                        className="add-beneficiary-button"
                        iconName="add"
                        size="small"
                        type="secondary"
                        text="ADD BENEFICIARY"
                    />
                </div>
                :
                <div>
                    <h5 className="no-top-margin">
                        Your <span className="medium"> beneficiary list </span>
                    </h5>

                    <OmApplicationBeneficiaryList count={numberOfBeneficiaries} className="sessioncamhidetext">
                        <div slot="beneficiary-cards">
                            {beneficiaryList.map((beneficiary, index) => {
                                return (
                                    <ApplicationBeneficiaryDetailsCard
                                        key={index}
                                        number={index + 1}
                                        firstName={beneficiary.value.firstName.value}
                                        surname={beneficiary.value.surname.value}
                                        editHandler={() => editHandler(beneficiary.id)}
                                        removeHandler={() => removeHandler(beneficiary.id)}
                                    />
                                )
                            })}
                        </div>
                    </OmApplicationBeneficiaryList>

                    <OmApplicationButton
                        onClick={applicationButtonClickHandler}
                        className="add-beneficiary-button"
                        iconName="add"
                        size="small"
                        type="secondary"
                        text="ADD BENEFICIARY"
                        actionType={addedMaxBeneficiaries}
                    />
                </div>
            }
        </OmEfpBeneficiaries>
    );
};

export default BeneficiariesPage;